<template>
  <div class="Base64">
    <div class="title-msg">
      画像をbase64のデータに変換するツールです。<br />
      サーバに保存したりしませんのでご心配なく。ちなみにSVGはうまくいきません！
    </div>
    <div class="searchbox">
      <h4>Load IMAGE FIle</h4>
      <input type="file" placeholder="~.png" @change="onDrop">
    </div>

    <h4>Output</h4>
    <div class="qr-container">
      <div class="section-title">Loaded Image</div>
      <img :src="ImageData" alt="" style="width:100%;" />
      <div class="section-title">Encoded Base64 Data</div>
      <button class="btn" v-on:click="copyBase64" :disabled="isDisabled">Copy to Clipboard</button>
      <pre>
{{ ImageData }}
      </pre>
    </div>
  </div>
</template>

<script>
import copy from 'copy-to-clipboard'
export default {
  name: 'Base64',
  metaInfo () {
    return {
      title: 'Base 64 Encoder',
      titleTemplate: null,
      link: [
        {
          vmid: 'canonical',
          rel: 'canonical',
          href: 'https://abierre.com/tools/base64'
        },
        {
          vmid: 'alternate',
          rel: 'alternate',
          hreflang: 'ja',
          href: 'https://abierre.com/tools/base64'
        }
      ],
      script: [
        {
          vmid: 'jsonld',
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Article',
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': 'https://abierre.com/tools/base64'
            },
            headline: 'Base 64 Encoder',
            articleBody: '画像をbase64にエンコードするツールです。',
            articleSection: 'Tools',
            image: [
              'https://img.abierre.com/OGP8.png'
            ],
            datePublished: '2018-06-14T21:15:09+09:00',
            dateModified: '2018-06-14T21:15:09+09:00',
            author: {
              '@type': 'Person',
              name: 'ISSIE'
            },
            publisher: {
              '@type': 'Organization',
              name: 'ABIERRE',
              logo: {
                '@type': 'ImageObject',
                url: 'https://img.abierre.com/JsonLdImg.png'
              }
            },
            description: '画像をbase64にエンコードするツールです。'
          }),
          type: 'application/ld+json'
        }
      ],
      meta: [
        { charset: 'utf-8' },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'Base 64 Encoder'
        },
        {
          vmid: 'description',
          property: 'description',
          content: '画像をbase64にエンコードするツールです。'
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: '画像をbase64にエンコードするツールです。'
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: 'https://abierre.com/tools/base64'
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'product'
        },
        {
          vmid: 'og:image',
          name: 'og:image',
          content: 'https://img.abierre.com/OGP63.png'
        },
        {
          vmid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          vmid: 'twitter:image',
          name: 'twitter:image',
          content: 'https://img.abierre.com/OGP63.png'
        },
        {
          vmid: 'twitter:text:title',
          name: 'twitter:text:title',
          content: 'Base 64 Encoder'
        },
        {
          vmid: 'twitter:title',
          name: 'twitter:title',
          content: 'Base 64 Encoder'
        },
        {
          vmid: 'twitter:description',
          name: 'twitter:description',
          content: '画像をbase64にエンコードするツールです。'
        }
      ]
    }
  },
  data () {
    return {
      ImageData: null,
      isDisabled: 'disabled'
    }
  },
  props: {
  },
  components: {
  },
  created: function () {
  },
  updated: function () {
  },
  mounted: function () {
    document.dispatchEvent(new Event('x-app-rendered'))
  },
  watch: {
    ImageData: function () {
      if (this.ImageData === null) {
        this.isDisabled = 'disabled'
      } else {
        this.isDisabled = false
      }
    }
  },
  methods: {
    onDrop: function (e) {
      var file = e.target.files[0]
      var reader = new FileReader()

      reader.onload = e => (
        this.ImageData = e.target.result
      )
      reader.readAsDataURL(file)
    },
    copyBase64: function () {
      if (!this.ImageData) return
      copy(this.ImageData)
    }
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
.Base64 {
  display : block;
  padding: 20px;
  text-align: left;
  height: 100%;
  width: 100%;
  max-width: 1440px;left:0;right:0;margin-left:auto;margin-right:auto;
}

a {
text-decoration: none;
}

a:hover {
}

.qr-frame {
  display : block;
  width: 30%;
  height: 0 auto;
  margin-top: 10px;
  margin-left: 30px;
  text-align: left;
  width: 100%;
}

.qr-container {
  text-align: left;
  border: solid 1px rgba(5, 40, 65,1);
  border-radius: 3px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 30px;
  padding-bottom: 20px;
  margin-bottom:200px;
  width: 96%;
}

.title-head {
  font-size : 30px;
  margin-bottom: 10px;
  margin-left: 10px;
  padding-top: 3%;
}

.title-msg {
  padding-bottom: 2%;
  font-size:.8rem;
}

.section-head {
 font-size : 20px;
 padding: 2%;
}

input:focus {
outline: none;
}

input {
display: block;
width: 96%;
padding:5px;
margin-top: 0.3%;
margin-bottom: 2%;
border-radius : 3px;
}

</style>
