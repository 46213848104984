<template>
  <div class="ArticleSearch">
    <div class="search-box">
      <p>「ブログ記事の検索ページ」をかっこよく言ってみただけです。</p>
      <p>Elasticsearchを用いた全文検索となっています。</p>
      <p>表記揺れに対応している関係で、近しい単語も検索されます。</p>
      <br />
      <input
        class="ibox"
        type="text"
        placeholder="Search ABIERRE"
        v-model="target"
        v-on:keyup.enter="search"
        ref="as"
      >
      <button
        class="btn"
        v-on:click="search"
        :disabled="isDisabled"
      >
        Search
      </button>
    </div>
    <div class='stillmsg' v-if="still">
      <p>{{ stillmsg }}</p>
    </div>
    <div class="result-box">
      <div class="result-frame" v-for="result of results" :key="result.ResultMeta.Id">
        <div class="result-title">
          <a :href="checkId(result.ResultMeta.Id)">{{ result.ResultMeta.Title }}</a>
        </div>
        <div
          class="result-inner-frame"
          v-for="(cnt, i) of result.Content"
          :key="i"
          :index="i"
        >
          <p v-html="cnt"></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'ArticleSearch',
  metaInfo () {
    return {
      title: 'Article Search',
      titleTemplate: null,
      link: [
        {
          vmid: 'canonical',
          rel: 'canonical',
          href: 'https://abierre.com/tools/search'
        },
        {
          vmid: 'alternate',
          rel: 'alternate',
          hreflang: 'ja',
          href: 'https://abierre.com/tools/search'
        }
      ],
      script: [
        {
          vmid: 'jsonld',
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Article',
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': 'https://abierre.com/tools/search'
            },
            headline: 'Article Search',
            articleBody: '記事のリアルタイム検索ができます。',
            articleSection: 'Tools',
            image: [
              'https://img.abierre.com/OGP8.png'
            ],
            datePublished: '2018-08-01T01:00:07+09:00',
            dateModified: '2018-08-04T11:35:07+09:00',
            author: {
              '@type': 'Person',
              name: 'ISSIE'
            },
            publisher: {
              '@type': 'Organization',
              name: 'ABIERRE',
              logo: {
                '@type': 'ImageObject',
                url: 'https://img.abierre.com/JsonLdImg.png'
              }
            },
            description: '記事のリアルタイム検索ができます。'
          }),
          type: 'application/ld+json'
        }
      ],
      meta: [
        { charset: 'utf-8' },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'Article Search'
        },
        {
          vmid: 'description',
          name: 'description',
          content: '記事のリアルタイム検索ができます。'
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: '記事のリアルタイム検索ができます。'
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: 'https://abierre.com/tools/search'
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'product'
        },
        {
          vmid: 'twitter:title',
          name: 'twitter:title',
          content: 'Article Search'
        },
        {
          vmid: 'twitter:description',
          name: 'twitter:description',
          content: '記事のリアルタイム検索ができます。'
        }
      ]
    }
  },
  data () {
    return {
      target: null,
      oldTarget: '',
      results: null,
      notFound: false,
      still: false,
      stillmsg: 'Still Loading',
      isDisabled: 'disabled',
      axiosconfig: {
        baseURL: 'https://api.abierre.com',
        timeout: 10000,
        headers: {
          'X-ABIERRE-REQID': 'https://abierre.com',
          'Content-Type': 'application/json'
        }
      }
    }
  },
  components: {
  },
  created: function () {
  },
  updated: function () {
  },
  mounted: function () {
  },
  watch: {
    target: function () {
      if (this.target === null || this.target.length === 0) {
        this.isDisabled = 'disabled'
      } else {
        this.isDisabled = false
      }
    }
  },
  methods: {
    checkId: function (id) {
      if (id === null) return
      if (id === '5bb73e30bcecc7a5252b8a14') {
        return '/history'
      }
      if (id === '5bc06d91bcecc7a5253897a0') {
        return '/notice'
      }
      if (id === '5bc06b8cbcecc7a525389443') {
        return '/'
      }
      return '/article/' + id
    },
    search: function () {
      this.$refs.as.blur()
      this.still = true
      this.results = null
      this.isDisabled = 'disabled'
      var _http = axios.create(this.axiosconfig)
      var postData = {
        target: this.target
      }

      return _http.post(
        '/article/search',
        postData
      ).then((response) => {
        if (response.status === 200) {
          this.still = false
          this.results = response.data
          this.isDisabled = false
        }

        if (response.status === 204) {
          this.still = true
          this.stillmsg = 'No Content'
          this.isDisabled = false
        }
      }).catch((err) => {
        if (err.response.status !== undefined) {
          if (err.response.status === 400) {
            this.still = true
            this.stillmsg = 'Not Found'
            this.isDisabled = false
          }
        }
      })
    }
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
.ArticleSearch {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom:200px;
  text-align: left;
  height: 100%;
  width: 100%;
  max-width: 1440px;left:0;right:0;margin-left:auto;margin-right:auto;
}

.search-box {
  padding-top: 20px;
}
.search-box p {
  font-size:.8rem;
}

.rsult-box {
  padding: 15px;
  font-size: .8rem;
}

.ibox {
  font-size: 2.2rem;
  font-weight: 300;
  display: block;
  padding : 10px;
  margin-bottom: 10px;
  border-top: hidden;
  border-left: hidden;
  border-right: hidden;
  border-bottom: 1px solid rgba(5, 40, 65, 1);
  border-radius: 0px;
  width: 100%;
  outline : none;
  -webkit-appearance: none;
}

.ibox:focus {
  background : #fff;
}

.result-frame {
  margin: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  background : #f4f4f4;
  padding: 15px;
  overflow: hidden;
  transition: background 0.2s ease-in-out;
  box-shadow: 0px 3px 30px rgba(0,0,0,.2);
}

.result-frame:hover {
  background : #fff;
}

.result-inner-frame {
  margin: 5px;
  border : 1px solid #ddd;
  border-radius: 5px;
  background : #fffefe;
  padding: 5px;
  height: 0 auto;
  vertical-align: middle;
  overflow: hidden;
}

.result-inner-frame p {
  font-size: .8rem;
}

.result-title {
  font-size: 1.2em;
  line-height: 1.6em;
  border-left: 5px solid #126c82;
  padding-left: 10px;
}
.stillmsg {
  font-size: 1.2em;
  line-height: 1.6em;
  padding-left: 20px;
}

.modInfo {
padding-left: 20px;
}
</style>
