<template>
  <div class='Notice'>
    <div class='article-content'>
      <vue-markdown :source='article.Content'></vue-markdown>
    </div>
    <div class='editArt' v-on:click='editArticle'>...</div>
    <div class='ajustFooter'><Footer /></div>
  </div>
</template>
<script>
import axios from 'axios'
import VueMarkdown from 'vue-markdown'
import Prism from 'prismjs'
import Footer from '@/components/Footer'
export default {
  name: 'Notice',
  metaInfo () {
    return {
      title: 'Notice',
      titleTemplate: null,
      ink: [
        {
          vmid: 'canonical',
          rel: 'canonical',
          href: 'https://abierre.com/notice'
        },
        {
          vmid: 'alternate',
          rel: 'alternate',
          hreflang: 'ja',
          href: 'https://abierre.com/notice'
        }
      ],
      script: [
        {
          vmid: 'jsonld',
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Article',
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': 'https://abierre.com/notice'
            },
            headline: 'Notice',
            articleBody: 'ABIERREの利用に関して諸注意などを掲載しています。',
            articleSection: 'notice',
            image: [
              'https://img.abierre.com/OGP8.png'
            ],
            datePublished: '2018-06-14T21:15:09+09:00',
            dateModified: '2018-06-14T21:15:09+09:00',
            author: {
              '@type': 'Person',
              name: 'ISSIE'
            },
            publisher: {
              '@type': 'Organization',
              name: 'ABIERRE',
              logo: {
                '@type': 'ImageObject',
                url: 'https://img.abierre.com/JsonLdImg.png'
              }
            },
            description: 'ABIERREの利用に関して諸注意などを掲載しています。'
          }),
          type: 'application/ld+json'
        }
      ],
      meta: [
        { charset: 'utf-8' },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'Notice'
        },
        {
          vmid: 'description',
          property: 'description',
          content: 'ABIERREの利用に関して諸注意などを掲載しています。'
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: 'ABIERREの利用に関して諸注意などを掲載しています。'
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: 'https://abierre.com/notice'
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'product'
        },
        {
          vmid: 'og:image',
          name: 'og:image',
          content: 'https://img.abierre.com/OGP63.png'
        },
        {
          vmid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          vmid: 'twitter:image',
          name: 'twitter:image',
          content: 'https://img.abierre.com/OGP63.png'
        },
        {
          vmid: 'twitter:text:title',
          name: 'twitter:text:title',
          content: 'Notice'
        },
        {
          vmid: 'twitter:title',
          name: 'twitter:title',
          content: 'Notice'
        },
        {
          vmid: 'twitter:description',
          name: 'twitter:description',
          content: 'ABIERREの利用に関して諸注意などを掲載しています。'
        }
      ]
    }
  },
  data () {
    return {
      id: '5bc06d91bcecc7a5253897a0',
      axiosconfig: {
        baseURL: 'https://api.abierre.com',
        timeout: 10000,
        headers: {
          'X-ABIERRE-REQID': 'https://abierre.com',
          'Content-Type': 'application/json'
        }
      },
      article: {
        Title: 'Title',
        Cdate: '0000/00/00',
        Rdate: '0000/00/00',
        Content: '## Still loading  ...'
      }
    }
  },
  components: {
    VueMarkdown,
    Footer
  },
  created: function () {
    this.getArticle()
  },
  updated: function () {
    this.highlight()
  },
  mounted () {
    document.dispatchEvent(new Event('x-app-rendered'))
  },
  watch: {
    article: function () {
    },
    $route: function () {
      this.getArticle()
    }
  },
  beforeRouteEnter () {
    this.getArticle()
  },
  methods: {
    editArticle: function () {
      this.$router.push({ name: 'Editor', params: { id: this.id } })
    },
    getItemLS: function (key) {
      return JSON.parse(sessionStorage.getItem(key))
    },
    setItemLS: function (key, data) {
      var jsonData = JSON.stringify(data)
      sessionStorage.setItem(key, jsonData)
    },
    removeItemLS: function (key) {
      sessionStorage.removeItem(key)
    },
    highlight: function () {
      Prism.highlightAll()
    },
    getArticle: function () {
      var data = this.getItemLS('5b1a8324ef2031a4e30245a2')
      if (data !== null) {
        this.article = data
      } else {
        var _http = axios.create(this.axiosconfig)
        return _http.get('/article/' + this.id).then((response) => {
          this.article = response.data
          this.highlight()
          this.setItemLS('5b1a8324ef2031a4e30245a2', response.data)
        })
      }
    },
    reformatDate: function (d) {
      var _d = new Date(d)
      var DisplayDate = _d.getFullYear() + '.' + ('0' + (_d.getMonth() + 1)).slice(-2) + '.' + ('0' + _d.getDate()).slice(-2)
      return DisplayDate
    }
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
.Notice {
  display : block;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  width: 100%;
  max-width: 980px;left:0;right:0;margin-left:auto;margin-right:auto;
  overflow: hidden;
}
p {
font-size: 0.8rem;
}

.article-content {
  text-align: left;
  width: 100%;
  overflow: hidden;
}

.editArt {
color: #fff;
cursor:pointer;
margin-top:20px;
width:20px;
}
</style>
