<template>
  <div class="TextArea">
    <label
      class="input-label"
      :class="{
        focused: isFocus,
        notfocus: !isFocus,
        isEdit: isEdit
      }"
      @click="setFocus"
    >
      {{ label }}
      <span
        class="dispLength"
        :class="isReachedMaxLength ? 'alertC' : 'defaultC'"
        v-if="maxLength !== undefined && inputData.length > 0"
      >
        {{ inputData.length }} / {{ maxLength }}
      </span>
    </label>
    <div class="inputContainer">
    <textarea
      ref="textarea"
      class="textarea-box"
      :class="isReachedMaxLength ? 'alert' : 'default'"
      v-model="inputData"
      :maxlength="maxLength"
      @focus="onFocus"
      @blur="onBlur"
    >
    </textarea>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextArea',
  props: [
    'value',
    'label',
    'maxLength'
  ],
  data () {
    return {
      isFocus: false,
      isEdit: false,
      isReachedMaxLength: false
    }
  },
  computed: {
    inputData: {
      get () {
        return this.value + ''
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    inputData () {
      this.isEdit = false
      if (this.inputData === '') {
        this.isFocus = false
      } else {
        this.isFocus = true
      }

      if (this.maxLength === undefined) return
      if (this.inputData === '') return
      if (this.inputData.length > this.maxLength) {
        this.isReachedMaxLength = true
      } else {
        this.isReachedMaxLength = false
      }
    }
  },
  mounted () {
    if (this.maxLength === undefined) return
    if (this.inputData === '') return
    if (this.inputData.length > this.maxLength) {
      this.isReachedMaxLength = true
    } else {
      this.isReachedMaxLength = false
    }

    this.isEdit = false
    if (this.inputData === '') {
      this.isFocus = false
    } else {
      this.isFocus = true
    }
  },
  methods: {
    setFocus: function () {
      this.$refs.textarea.focus()
    },
    onFocus: function () {
      this.isFocus = true
      this.isEdit = true
    },
    onBlur: function () {
      this.isEdit = false
      if (this.inputData === null || this.inputData === '') {
        this.isFocus = false
      } else {
        this.isFocus = true
      }
    }

  }
}
</script>
<style scoped>
.TextArea {
  position: relative;
  margin-top:40px;
  display: flex;
  width: 100%;
}

.input-label {
  position: absolute;
  display: block;
  text-align: left;
  height: 28px;
  line-height: 16px;
  font-size: 13px;
  padding-top: 6px;
  width:0 auto;min-width: 250px;
  color: black;
  top: -30px;
  left: 0;
  z-index: 1;
  cursor: text;
}

.focused {
  color: #620;
  font-size: 15px;
  text-shadow: 0 1.36px 1.896px #c4b59daa,0 -2px 1px #fff;
  letter-spacing: 2px;
  transform: translate(0px,0px);
  transition-property: transform font-size;transition-duration: 0.2s;transition-delay: 0;
}
.notfocus {
  color: #777;
  transform: translate(10px,35px);
  transition-property: transform font-size;transition-duration: 0.2s;transition-delay: 0;
}

.isEdit {
  filter: drop-shadow( 0px 0px 2px rgba(234,245,30,.09));
}

.inputContainer {
  width: 100%;
  display: flex;
}

.textarea-box {
  background: none;
  display: block;
  resize: none;
  width: 100%;
  height: 380px;
  padding: 10px;
  outline: 0;
  font-size: 16.2px;
  font-weight: 100;
  line-height: 20px;
  color: #000;
  letter-spacing: 1px;
  border: solid 1px rgba(105, 140, 165,1);
  border-radius: 0px;
  -webkit-appearance:none;
  background-color: -apple-system-control-background;
  border-image-source: none;
  border-image-slice: none;
  border-image-width: none;
  border-image-outset: none;
  border-image-repeat: none;
  -webkit-rtl-ordering: logical;
  -webkit-user-select: text;
  flex-direction: column;
  resize: none;
  cursor: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  border: none;
  border-radius: 5px;
  box-shadow: -2px -2px 2px #fafafa inset, 1px 1px 1px 1px #ccaa4422 inset;
  background: #fffefa;

}

.textarea-box:focus {
  background: #fff;
  box-shadow: 0px 0px 4px #77220044 inset;
}

.dispLength {
  padding: 3px;
  font-size: 10px;
}

.unit {
  padding-top: 5px;
  padding-left: 5px;
}

.alert {
  color: red;
}

.default {
  color: black;
}

.alertC {
  color: red;
}
.defaultC {
  color: black;
}
</style>
