import { render, staticRenderFns } from "./MetaList.vue?vue&type=template&id=11131843&scoped=true&"
import script from "./MetaList.vue?vue&type=script&lang=js&"
export * from "./MetaList.vue?vue&type=script&lang=js&"
import style0 from "./MetaList.vue?vue&type=style&index=0&id=11131843&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11131843",
  null
  
)

export default component.exports