<template>
  <div class='IndexMeta'>
  </div>
</template>

<script>
export default {
  name: 'IndexMeta',
  metaInfo () {
    return {
      title: 'ABIERRE',
      titleTemplate: 'ABIERRE',
      meta: [
        { charset: 'utf-8' },
        {
          vmid: 'description',
          property: 'description',
          content: '「ITエンジニア」や「ITやりたいけどよくわからない」って方への技術支援を目的に活動しています！'
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'website'
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: 'https://abierre.com/'
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'ABIERRE'
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: 'https://img.abierre.com/OGP63.png'
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: '「ITエンジニア」や「ITやりたいけどよくわからない」って方への技術支援を目的に活動しています！'
        },
        {
          vmid: 'twitter:image:src',
          name: 'twitter:image:src',
          content: 'https://img.abierre.com/OGP63.png'
        },
        {
          vmid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          vmid: 'twitter:site',
          name: 'twitter:site',
          content: '@abierre_com'
        },
        {
          vmid: 'twitter:creator',
          name: 'twitter:creator',
          content: '@abierre_com'
        },
        {
          vmid: 'twitter:title',
          name: 'twitter:title',
          content: 'ABIERRE'
        },
        {
          vmid: 'twitter:description',
          name: 'twitter:description',
          content: '「ITエンジニア」や「ITやりたいけどよくわからない」って方への技術支援を目的に活動しています！'
        }
      ],
      script: [
        {
          vmid: 'jsonld',
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'WebSite',
            url: 'https://abierre.com',
            name: 'ABIERRE',
            publisher: {
              '@type': 'Organization',
              name: 'ABIERRE Information and Communications Technology System',
              url: 'https://abierre.com',
              logo: {
                '@type': 'ImageObject',
                url: 'https://img.abierre.com/JsonLdImg.png'
              }
            }
          }),
          type: 'application/ld+json'
        }
      ]
    }
  },
  mounted () {
    document.dispatchEvent(new Event('x-app-rendered'))
  }
}
</script>

<style scoped>
.IndexMeta {
  display : none;
}
</style>
