<template>
  <div class='Editor'>
    <header id='global-head'>
      <div id='gh-container'>
        <router-link to='/'>
          <img id='brand-logo' alt='brand-logo' src='https://img.abierre.com/logo_l.svg'/>
        </router-link>
        <div class='gh-menu'>
          <div class='gh-inner-menu'>
            <img class='expand' src='https://img.abierre.com/Expander.svg' alt='expand_editor' v-on:click='callFullScreen'>
            <div class='ebtn' v-on:click='updateArticle'>Update</div>
            <div class='ebtn' v-on:click='deleteArticle'>Delete</div>
          </div>
        </div>
      </div>
    </header>
    <div id='md-tools'>
      <div class='opbox'>
        <div class='delete-check'>
          <input type='checkbox' id='checkbox' v-model='checked'>
          <label for='delete'>Are you sure for delete this article.</label>
        </div>
      </div>
      <div class='title-head'>Edit Article</div>
      <div class='title-frame'>
        <label for=''>Title</label>
        <input class='ibox' type='text' aceholder='Title' v-model='article.Title'>
      </div>
      <div class='title-frame'>
        <label for=''>Tag</label>
        <input class='ibox' type='text' aceholder='Title' v-model='article.Tag'>
      </div>
      <div class='title-frame'>
        <label for='Token'>Token</label>
        <label for='Token-Error' class='msg401' v-bind:class={open401:is401}> ※ INVALID YOUR TOKEN</label>
        <input class='ibox' type='text' aceholder='Title' v-model="axiosconfig.headers['X-AUTH-TOKEN']">
      </div>
    </div>
    <div id='md'>
      <div id='md-input-frame'>
        <textarea id='md-input' placeholder='Type here ...' v-model='article.Content'>
        </textarea>
      </div>
      <div id='md-result-frame'>
        <div id='md-result'>
          <vue-markdown :source='article.Content'></vue-markdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import Prism from 'prismjs'
import axios from 'axios'
import Split from 'split.js'
export default {
  props: ['id'],
  name: 'Editor',
  metaInfo () {
    return {
      title: 'Edit Article',
      titleTemplate: '%s - ABIERRE',
      link: [
        {
          vmid: 'canonical',
          rel: 'canonical',
          href: 'https://abierre.com/edit/' + this.article.Id
        }
      ],
      meta: [
        { charset: 'utf-8' },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'Edit Article - ABIERRE'
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: 'Blog記事の編集を行います。'
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: 'https://abierre.com/edit' + this.article.Id
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'article'
        },
        {
          vmid: 'twitter:title',
          name: 'twitter:title',
          content: 'Edit Article - ABIERRE'
        },
        {
          vmid: 'twitter:description',
          name: 'twitter:description',
          content: 'Blog記事編集を行います。'
        },
        {
          vmid: 'robots',
          name: 'robots',
          content: 'noindex, nofollow'
        }
      ]
    }
  },
  data () {
    return {
      input_scroll_point: '',
      result_scroll_point: '',
      article: '',
      is401: false,
      axiosconfig: {
        baseURL: 'https://api.abierre.com',
        timeout: 10000,
        headers: {
          'X-AUTH-TOKEN': '',
          'X-ABIERRE-REQID': 'https://abierre.com',
          'Content-Type': 'application/json'
        }
      },
      checked: false,
      updateComplete: '',
      deleteComplete: ''
    }
  },
  components: {
    VueMarkdown
  },
  methods: {
    checkId (id) {
      if (id === null) return
      if (id === '5bb73e30bcecc7a5252b8a14') {
        return '/history'
      }
      if (id === '5bc06d91bcecc7a5253897a0') {
        return '/notice'
      }
      if (id === '5bc06b8cbcecc7a525389443') {
        return '/'
      }
      return '/article/' + id
    },
    callFullScreen () {
      var mdarea = document.getElementById('md')
      if (document.fullscreenEnabled) {
        mdarea.requestFullscreen()
      } else if (document.webkitFullscreenEnabled) {
        mdarea.webkitRequestFullscreen()
      } else if (document.mozFullScreenEnabled) {
        mdarea.mozRequestFullScreen()
      } else if (document.msFullscreenEnabled) {
        mdarea.msRequestFullscreen()
      }
    },
    getISODate () {
      var date = new Date()
      var addZero = function (time) {
        return ('0' + time).slice(-2)
      }
      var ISO8601Date = date.getFullYear() + '-' +
        addZero(date.getMonth() + 1) + '-' +
        addZero(date.getDate()) +
        'T' +
        addZero(date.getHours()) + ':' +
        addZero(date.getMinutes()) + ':' +
        addZero(date.getSeconds()) + '+' +
        addZero(date.getTimezoneOffset() / (-60)) + ':00'
      return ISO8601Date
    },
    deleteArticle () {
      if (!this.checked) {
        return false
      }
      this.article.Id = this.id
      var _http = axios.create(this.axiosconfig)
      return _http.delete('/article/' + this.id).then((res) => {
        if (res.status === 204) {
          this.deleteComplete = true
        }
      }).catch((err) => {
        if (err.response.status === 401) {
          this.is401 = true
        }
      })
    },
    updateArticle () {
      this.article.Id = this.id
      this.article.Rdate = this.getISODate()
      var postdata = JSON.stringify(this.article)
      var _http = axios.create(this.axiosconfig)
      return _http.post('/article/' + this.id, postdata).then((res) => {
        if (res.status === 204) {
          this.updateComplete = true
        }
      }).catch((err) => {
        if (err.response.status === 401) {
          this.is401 = true
        }
      })
    },
    highlight () {
      Prism.highlightAll()
    },
    handleInputScroll () {
      this.input_scroll_point = document.getElementById('md-input').scrollTop
    },
    handleResultScroll () {
      this.result_scroll_point = document.getElementById('md-result').scrollTop
    },
    getArticle () {
      if (this.id.length !== 24) {
        this.$router.push({ name: 'Creator' })
        return
      }
      var _http = axios.create(this.axiosconfig)
      return _http.get('/article/' + this.id).then((response) => {
        this.article = response.data
        this.articleContentMarked = response.data.Content
        this.highlight()
      }).catch((err) => {
        if (err.response.status === 404) {
          this.$router.push({ name: 'Creator' })
        }
      })
    }
  },
  mounted () {
    if (document.body.style.overflow === 'hidden') {
      document.body.style.overflow = 'scroll'
    }
    document.getElementById('md-input').addEventListener('scroll', this.handleInputScroll, { passive: true })
    document.getElementById('md-result').addEventListener('scroll', this.handleResultScroll, { passive: true })
    this.getArticle()
    Split(['#md-input-frame', '#md-result-frame'], {
      sizes: [30, 70],
      direction: 'horizontal',
      cursor: 'col-resize',
      minSize: 100,
      elementStyle (dimension, size, gutterSize) {
        return {
          'flex-basis': 'calc(' + size + '% - ' + gutterSize + 'px)'
        }
      },
      gutterStyle (dimension, gutterSize) {
        return {
          'flex-basis': gutterSize + 'px',
          'background-color': '#c16d01',
          'background-image': 'url(https://img.abierre.com/dragpoint.svg)',
          'background-repeat': 'no-repeat'
        }
      }
    })
  },
  watch: {
    updateComplete () {
      if (this.updateComplete === true) {
        sessionStorage.clear()
        var uri = this.checkId(this.id)
        this.$router.push(uri)
      } else {
        return false
      }
    },
    deleteComplete () {
      if (this.deleteComplete === true) {
        sessionStorage.clear()
        this.$router.push('/')
      } else {
        return false
      }
    },
    article: {
      handler () {
        this.highlight()
      },
      deep: true
    },
    input_scroll_point () {
      document.getElementById('md-result').scrollTop = this.input_scroll_point
    },
    result_scroll_point () {
      document.getElementById('md-input').scrollTop = this.result_scroll_point
    }
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>

.Editor {
  min-height: 100vh;
  height: 100%;
  text-align: center;
  padding-top:80px;
}

.opbox {
  left: 0;
}
.opbox > .btn {
  display: inline-block;
  text-align: left;
  left: 0;
}

.title-head {
  font-size : 30px;
  margin-bottom: 10px;
  margin-left: 10px;
  padding-top: 3%;
  text-align: left;
}

.title-frame {
  padding: 5px;
  left:0;
  text-align:left;
  margin-bottom: 8px;
}

#md-tools {
  height: 6%;
  background: none;
  vertical-align: middle;
  padding: 10px;
}

#md {
  display : flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: 95vh;
  margin: 20px;
  width: 100% - 20px;
}

#md:-webkit-full-screen {
top:0;left:0;
width: 100vw;
height: 95vh;
}

#md:fullscreen {
top:0;left:0;
width: 100vw;
height: 95vh;
}

#md-input-frame{
}

#md-input-frame > * {
  min-width: 0px;
}

#md-input {
  display: block;
  height : 95vh;
  width: 100%;
  border : none;
  background : #fff;
  padding : 20px;
  resize: none;
  overflow : scroll;
  border: 1px solid #aaa;
  font-size: 14px;
  min-width: 0px;
  letter-spacing: 0px;
}

#md-input:focus {
  outline : none;
}

#md-result-frame {
  min-width: 0px;
}

#md-result {
  text-align : left;
  height : 95vh;
  background : #fff;
  padding : 20px;
  overflow : scroll;
  border: 1px solid #aaa;
  min-width: 0px;
  max-height: 95vh;
}

#md-result > * {
  min-width: 0px;
}

.ibox {
  display: block;
  padding : 5px;
  border-radius: 3px;
  border: 1px solid #aaa;
  width: 100%;
  font-size: 16px;
}

.ibox:focus {
  outline : none;
}

.msg401 {
display: none;
color : #ff4400;
fot-size: 1.5rem;
}

.open401 {
display: inline;
}

.delete-check {
text-align:right;
margin-right:15px;
}

.split {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

.split {
    overflow-y: auto;
    overflow-x: hidden;
}

.split, .gutter.gutter-horizontal {
    float: left;
}

.split, .gutter.gutter-horizontal {
    height: 300px;
}

</style>
