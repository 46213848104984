<template>
  <div class="NotFound">
    <div class="NFcontent">
      <div class="c404box">
        <img class="c404img" alt="SampleOgpImage" src="https://img.abierre.com/OGP8.png" />
      </div>
      <h1>{{ msg }}</h1>
      <h2>指定のURI "{{ path }}" は存在しません。</h2>
      <hr />
      <h4><a href="/">HOMEにもどる ?</a></h4><br />
      <h4><a href="/tools/search">Blog検索でもする ？</a></h4><br />
      <h4><a href="/history">更新情報でも見る ？</a></h4><br />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  metaInfo: {
    title: '404 Not Found',
    titleTemplate: null,
    link: [
      {
        vmid: 'canonical',
        rel: 'canonical',
        href: 'https://abierre.com/'
      }
    ],
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'og:title',
        property: 'og:title',
        content: '404 Not Found'
      },
      {
        vmid: 'description',
        property: 'description',
        content: '404 Not Found'
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: '404 Not Found'
      },
      {
        vmid: 'og:url',
        property: 'og:url',
        content: 'https://abierre.com/'
      },
      {
        vmid: 'og:type',
        property: 'og:type',
        content: 'article'
      },
      {
        vmid: 'twitter:title',
        name: 'twitter:title',
        content: '404 Not Found'
      },
      {
        vmid: 'twitter:description',
        name: 'twitter:description',
        content: '404 Not Found'
      },
      {
        vmid: 'robots',
        name: 'robots',
        content: 'noindex, nofollow'
      }
    ]
  },
  data () {
    return {
      msg: '404 Not Found',
      path: ''
    }
  },
  created: function () {
  },
  mounted: function () {
    this.path = location.pathname
  },
  watch: {
    $route: function () {
      this.path = location.pathname
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.NFcontent {
  position: static;
  padding: 50px;
  top:0;
  left:0;
  text-align: left;
  width: 100%;
  overflow:hidden;
}

h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.c404box {
position: relative;
width: 100%;
margin-bottom: 50px;
}

.c404img {
position: relative;
display:block;
height: 0 auto;
width: 100%;
left:0;
right:0;
margin-left: auto;
margin-right: auto;
text-align: center;
filter: drop-shadow( 0px 0px 30px rgba(0,0,0,.2));
-webkit-filter: drop-shadow( 0px 0px 30px rgba(0,0,0,.2));
border-radius: 5px;
}
</style>
