<template>
  <div class="MetaList">
    <header
      id="global-head"
      :class="[ targetId !== null ? 'expandTBCase' : 'notExpandTBCase' ]"
    >
      <div
        id="gh-container"
      >
        <router-link to="/">
          <img id="brand-logo" alt="brand-logo" src="https://img.abierre.com/def_logo.svg"/>
        </router-link>

        <div class="gh-menu">
          <div class="gh-inner-menu">
            <img
              class="gh-close-icon"
              alt="close"
              src="https://img.abierre.com/closer-icon.svg"
              v-on:click.prevent="removeTarget"
              v-if="targetId !== null && finAnimate"
            />
            <button
             class="gh-btn"
             v-scroll-to="{ el: '#info', offset: -80}"
             v-if="targetId == null"
             key="gh-info"
            >
              Informations
            </button>
            <button
             class="gh-btn"
             v-scroll-to="{ el: '#tools', offset: -80}"
             v-if="targetId == null"
             key="gh-tools"
            >
              Tools
            </button>
            <button
             class="gh-btn"
             v-scroll-to="{ el: '#blog', offset: -80}"
             v-if="targetId == null"
             key="gh-blog"
            >
              Blog
            </button>
            <a
              href="/tools/search"
              v-if="targetId !== null && finAnimate && targetMenu === 'article'"
              key="gh-search"
            >
              <div
                class="ebtn"
                v-on:click="toggleColorBackgorund"
                v-if="targetId !== null && finAnimate && targetMenu === 'article'"
                key="gh-toggle-color"
              >
                Blog記事検索
              </div>
            </a>
            <div
              class="ebtn"
              v-on:click="toggleColorBackgorund"
              v-if="targetId === 'color' && finAnimate"
              key="gh-toggle-color"
            >
              Toggle Base Color
            </div>
          </div>
        </div>
      </div>
    </header>

    <Index />

    <transition-group>

      <div
        id="summaryContent"
        key="summary"
      >
        <Summary />
      </div>

      <div
        class="title"
        key="info-title"
      >
        <div
          id="info"
          class="meta-title"
        >
         Informations
        </div>
      </div>

      <div
        id="b-history"
        class="base-frame"
        key="history"
      >
        <div
          id="history"
          class="article-frame"
          :class="[ targetId === 'history' ? 'expandf' : 'notExpand' ]"
          :disabled="processing"
        >
          <router-link to="/history">
          <div
            class="article-over-frame InfoOverFrame"
            :class="{expandf:(targetId === 'history') }"
          >
            <div class="overCover overInfo">History</div>
            <div
              class="info-title"
              :class="{ expandTitle:(targetId === 'history') }"
            >
              <span
               :class="{ ajustTitle:(targetId === 'history') }"
              >
               更新・修正情報
              </span>
            </div>
          </div>
          </router-link>
          <div
            class="article-under-frame"
            v-if="targetId === 'history'"
          >
            <div class="article-content">
              <History v-if="targetId === 'history' && finAnimate"/>
            </div>
          </div>
        </div>
      </div>
<!---->
      <div
        id="b-notice"
        class="base-frame"
        key="notice"
      >
        <div
          id="notice"
          class="article-frame"
          :class="[ targetId === 'notice' ? 'expandf' : 'notExpand' ]"
          :disabled="processing"
        >
          <router-link to="/notice">
          <div
            class="article-over-frame InfoOverFrame"
            :class="{expandf:(targetId === 'notice')}"
          >
            <div class="overCover overInfo">Notice</div>
            <div
              class="info-title"
              :class="{ expandTitle:(targetId === 'notice') }"
            >
              <span
               :class="{ ajustTitle:(targetId === 'notice') }"
              >
                プライバシーポリシー・免責事項
              </span>
            </div>
          </div>
          </router-link>
          <div
            class="article-under-frame"
            v-if="targetId === 'notice'"
          >
            <div class="article-content">
              <Notice v-if="targetId === 'notice' && finAnimate"/>
            </div>
          </div>
        </div>
      </div>

      <div
        id="b-contact"
        class="base-frame"
        key="contact"
      >
        <div
          id="contact"
          class="article-frame"
          :class="[ targetId === 'contact' ? 'expandf' : 'notExpand' ]"
          :disabled="processing"
        >
          <router-link to="/contact">
          <div
            class="article-over-frame InfoOverFrame"
            :class="{expandf:(targetId === 'contact')}"
          >
            <div class="overCover overInfo">Contact</div>
            <div
              class="info-title"
              :class="{ expandTitle:(targetId === 'contact') }"
            >
              <span
               :class="{ ajustTitle:(targetId === 'contact') }"
              >
                お問い合わせ
              </span>
            </div>
          </div>
          </router-link>
          <div
            class="article-under-frame"
            v-if="targetId === 'contact'"
          >
            <div class="article-content">
              <Contact v-if="targetId === 'contact' && finAnimate"/>
            </div>
          </div>
        </div>
      </div>

      <div
        class="title"
        key="tools-title"
      >
        <div
          id="tools"
          class="meta-title"
        >
          Tools
        </div>
      </div>

      <div
        id="b-search"
        class="base-frame"
        key="search"
      >
        <div
          id="search"
          class="article-frame"
          :class="[ targetId === 'search' ? 'expandf' : 'notExpand' ]"
          :disabled="processing"
        >
          <router-link to="/tools/search">
          <div
            class="article-over-frame ToolsOverFrame"
            :class="{expandf:(targetId === 'search')}"
          >
            <div class="overCover overTools">Article Search</div>
            <div
              class="tools-title"
              :class="{ expandTitle:(targetId == 'search') }"
            >
              <span
               :class="{ ajustToolsTitle:(targetId === 'search') }"
              >
                ブログ記事の全文検索
              </span>
            </div>
          </div>
          </router-link>
          <div
            class="article-under-frame"
            v-if="targetId === 'search'"
          >
            <div class="article-content">
              <ArticleSearch v-if="targetId === 'search' && finAnimate"/>
            </div>
          </div>
        </div>
      </div>

      <div
        id="b-base64"
        class="base-frame"
        key="base64"
      >
        <div
          id="base64"
          class="article-frame"
          :class="[ targetId === 'base64' ? 'expandf' : 'notExpand' ]"
          :disabled="processing"
        >
          <router-link to="/tools/base64">
            <div
              class="article-over-frame ToolsOverFrame"
              :class="{expandf:(targetId === 'base64')}"
            >
              <div class="overCover overTools">Base64 Encoder</div>
              <div
                class="tools-title"
                :class="{ expandTitle:(targetId === 'base64')}"
              >
                <span
                 :class="{ ajustToolsTitle:(targetId === 'base64')}"
                >
                  画像のBase64エンコーダ
                </span>
              </div>
            </div>
          </router-link>
          <div
            class="article-under-frame"
            v-if="targetId === 'base64'"
          >
            <div class="article-content">
              <base64 v-if="targetId === 'base64' && finAnimate"/>
            </div>
          </div>
        </div>
      </div>

      <div
        id="b-color"
        class="base-frame"
        key="color"
      >
        <div
          id="color"
          class="article-frame"
          :class="[ targetId === 'color' ? 'expandf' : 'notExpand' ]"
          :disabled="processing"
        >
          <router-link to="/tools/color">
            <div
              class="article-over-frame ToolsOverFrame"
              :class="{expandf:(targetId === 'color')}"
            >
              <div class="overCover overTools">Color Search</div>
              <div
                class="tools-title"
                :class="{ expandTitle:(targetId === 'color')}"
              >
                <span
                 :class="{ ajustToolsTitle:(targetId === 'color') }"
                >
                  色の調査・解析
                </span>
              </div>
            </div>
          </router-link>
          <div
            class="article-under-frame"
            v-if="targetId === 'color'"
          >
            <div class="article-content">
              <color
                :black="black"
                v-if="targetId === 'color' && finAnimate"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        id="b-contrast"
        class="base-frame"
        key="contrast"
      >
        <div
          id="contrast"
          class="article-frame"
          :class="[ targetId === 'contrast' ? 'expandf' : 'notExpand' ]"
          :disabled="processing"
        >
          <router-link to="/tools/contrast">
            <div
              class="article-over-frame ToolsOverFrame"
              :class="{expandf:(targetId === 'contrast')}"
            >
              <div class="overCover overTools">Contrast Analyzer</div>
              <div
                class="tools-title"
                :class="{ expandTitle:(targetId === 'contrast')}"
              >
                <span
                 :class="{ ajustToolsTitle:(targetId === 'contrast') }"
                >
                  コントラスト比の解析
                </span>
              </div>
            </div>
          </router-link>
          <div
            class="article-under-frame"
            v-if="targetId === 'contrast'"
          >
            <div class="article-content">
              <ContrastAnalyzer
                :black="black"
                v-if="targetId === 'contrast' && finAnimate"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        id="b-qr"
        class="base-frame"
        key="qr"
      >
        <div
          id="qr"
          class="article-frame"
          :class="[ targetId === 'qr' ? 'expandf' : 'notExpand' ]"
          :disabled="processing"
        >
          <router-link to="/tools/qr">
            <div
              class="article-over-frame ToolsOverFrame"
              :class="{expandf:(targetId === 'qr')}"
            >
              <div class="overCover overTools">QRcode Creator</div>
              <div
                class="tools-title"
                :class="{ expandTitle:(targetId === 'qr') }"
              >
                <span
                 :class="{ ajustToolsTitle:(targetId === 'qr') }"
                >
                  QRコード作成ツール
                </span>
              </div>
            </div>
          </router-link>
          <div
            class="article-under-frame"
            v-if="targetId === 'qr'"
          >
            <div class="article-content">
              <qr
                v-if="targetId === 'qr' && finAnimate"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        id="b-whois"
        class="base-frame"
        key="whois"
      >
        <div
          id="whois"
          class="article-frame"
          :class="[ targetId === 'whois' ? 'expandf' : 'notExpand' ]"
          :disabled="processing"
        >
          <router-link to="/tools/whois">
            <div
              class="article-over-frame ToolsOverFrame"
              :class="{expandf:(targetId === 'whois')}"
            >
              <div class="overCover overTools">Whois Search</div>
              <div
                class="tools-title"
                :class="{ expandTitle:(targetId == 'whois') }"
              >
                <span
                 :class="{ ajustToolsTitle:(targetId === 'whois') }"
                >
                  Whois検索ツール
                </span>
              </div>
            </div>
          </router-link>
          <div
            class="article-under-frame"
            v-if="targetId === 'whois'"
          >
            <div class="article-content">
              <whois v-if="targetId === 'whois' && finAnimate"/>
            </div>
          </div>
        </div>
      </div>

      <div
        class="title"
        key="title"
      >
        <div
          id="blog"
          class="meta-title"
        >
          Blog Articles
        </div>
      </div>

      <div class="sort-menu" key="sorttag">
        <span class="f12">Sort : </span>
        <button
          class="gh-btn"
          key="gh-tag"
          v-on:click="sortArticle('tag')"
        >
          Tag
        </button>
        <button
          class="gh-btn"
          key="gh-rdate"
          v-on:click="sortArticle('rdate')"
        >
          Modified
        </button>
         <button
          class="gh-btn"
          key="gh-cdate"
          v-on:click="sortArticle('cdate')"
        >
          Created
        </button>
      </div>

      <div
        v-for="(article, index) of articles"
        :id="'b-' + article.Id"
        class="base-frame"
        :key="article.Id"
        :data-index="index"
        v-show="article.Tag !== 'menu'"
      >
        <div
          :id="article.Id"
          class="article-frame"
          :class="[ article.Id === targetId ? 'expandf' : 'notExpand' ]"
          :disabled="processing"
        >
          <router-link
            :to="'/article/' + article.Id"
          >
            <div
              :id="'of-' + article.Id"
              class="article-over-frame"
              :style="[article.Id === targetId && finAnimate? {background: getExpandBackgroundColorByTag(article.Tag)} : {background: getBackgroundColorByTag(article.Tag)}]"
              :class="{ expandf:(article.Id === targetId)}"
            >
              <div
                class="overCover overArticle"
                :style="{color: getExpandBackgroundColorByTag(article.Tag)}"
                v-if="article.Id !== targetId"
              >
                {{ getShortCoverNameByTag(article.Tag) }}
              </div>
              <div
                class="overCover overArticle"
                v-else
              >
                {{ getCoverNameByTag(article.Tag) }}
              </div>
              <div
                class="article-title"
                :class="[ article.Id === targetId ? 'expandTitle' : 'notExpandTitle' ]"
                :style="[ article.Id === hoverId ? articleTitleHoverStyle : {} ]"
                @mouseenter="setHoverStyle(article.Id, article.Tag)"
                @mouseleave="purgeHoverStyle"
              >
                <span
                 :class="{ ajustArticleTitle:(article.Id === targetId) }"
                >
                {{ article.Title }}
                </span>
              </div>
            </div>
          </router-link>
          <div
            class="article-under-frame"
            v-if="article.Id === targetId"
          >
            <div class="article-content">
              <keep-alive>
              <ArticleContent
                v-if="article.Id === targetId && finAnimate"
                v-bind:aid="article.Id"
              />
              </keep-alive>
            </div>
          </div>
        </div>
      </div>

      <div
        class="title"
        key="about-title"
      >
        <div
          id="about"
          class="meta-title"
        >
          About
        </div>
      </div>

      <div
        id="aboutContent"
        key="about"
      >
        <About />
      </div>

    </transition-group>
    <Footer class="MainFooter"/>
    <div class="fspacer" />
    <IndexMeta
      v-if="$route.path === '/'"
    />
  </div>
</template>
<script>
import Velocity from 'velocity-animate'
import api from '@/utils/api'
import ArticleContent from '@/components/ArticleContent'

import ArticleSearch from '@/components/ArticleSearch'
import Base64 from '@/components/Base64'
import ColorSearch from '@/components/ColorSearch'
import ContrastAnalyzer from '@/components/ContrastAnalyzer'
import QRcode from '@/components/QRcode'
import Whois from '@/components/Whois'

import Index from '@/components/Index'
import IndexMeta from '@/components/IndexMeta'
import About from '@/components/About'
import Notice from '@/components/Notice'
import Contact from '@/components/Contact'
import History from '@/components/History'
import Footer from '@/components/Footer'
import Summary from '@/components/Summary'

export default {
  name: 'MetaList',
  components: {
    color: ColorSearch,
    ContrastAnalyzer,
    whois: Whois,
    qr: QRcode,
    Base64,
    Index,
    IndexMeta,
    ArticleSearch,
    ArticleContent,
    About,
    History,
    Notice,
    Footer,
    Contact,
    Summary
  },
  mixins: [api],
  props: ['id'],
  data () {
    return {
      scroll: 0,
      overScroll: 0,
      articles: [],
      finAnimate: false,
      processing: false,
      toolProcessing: false,
      expandPoint: null,
      expandOffset: null,
      targetId: null,
      targetTop: 0,
      targetLeft: 0,
      targetMenu: null,
      black: false,
      touchStartLog: {},
      metaOrigin: null,
      sortOrder: 'asc',
      articleTitleHoverStyle: {},
      hoverId: null
    }
  },
  created () {
    this.getArticle('meta').then((articles) => {
      this.metaOrigin = articles
      const sorted = articles.sort(function (a, b) {
        return (a.Cdate < b.Cdate ? 1 : -1)
      })
      this.articles = sorted
    })
  },
  updated () {
    if (this.targetId !== null) return
    const str = this.$route.path
    const rp = str.split('/')
    if (this.$route.params.id === undefined) {
      if (rp[1] === 'tools') {
        this.addTarget(rp[2])
      } else if (rp[1] === 'notice' || rp[1] === 'history' || rp[1] === 'contact') {
        this.addTarget(rp[1])
      } else {
        this.targetId = null
      }
    } else {
      if (this.$route.params.id.length === 24) {
        const id = this.$route.params.id
        const About = '5bc06b8cbcecc7a525389443'
        const Notice = '5bc06d91bcecc7a5253897a0'
        const History = '5bb73e30bcecc7a5252b8a14'
        if (id === About) {
          this.$router.push('/', () => {})
          return
        }
        if (id === Notice) {
          console.log(id, 'Notice')
          this.addTarget('notice')
          return
        }
        if (id === History) {
          console.log(id, 'History')
          this.addTarget('history')
          return
        }

        this.addTarget(this.$route.params.id)
      } else {
        this.targetId = null
      }
    }
  },
  beforeRoutggeUpdate (to, from, next) {
    next()
  },
  watch: {
    overScroll (e) {
      this.sampler(e)
    },
    $route () {
      if (this.targetId !== null && this.$route.path === '/') {
        this.removeTarget()
      }
      const str = this.$route.path
      const rp = str.split('/')
      if (this.$route.params.id === undefined) {
        if (rp[1] === 'tools') {
          this.addTarget(rp[2])
        } else if (rp[1] === 'notice' || rp[1] === 'history' || rp[1] === 'contact') {
          this.addTarget(rp[1])
        } else {
          this.removeTarget()
        }
      } else {
        if (this.$route.params.id.length === 24) {
          const id = this.$route.params.id
          console.log(id)
          const About = '5bc06b8cbcecc7a525389443'
          const Notice = '5bc06d91bcecc7a5253897a0'
          const History = '5bb73e30bcecc7a5252b8a14'
          if (id === About) {
            console.log(id, 'About')
            this.$router.push('/', () => {})
            return
          }
          if (id === Notice) {
            console.log(id, 'Notice')
            this.addTarget('notice')
            return
          }
          if (id === History) {
            console.log(id, 'History')
            this.addTarget('history')
            return
          }
          console.log(id, '???')
          this.addTarget(this.$route.params.id)
        } else {
          this.removeTarget()
        }
      }
    }
  },
  beforeUpdate () {
  },
  mounted () {
    if (window.addEventListener) {
      window.addEventListener('DOMMouseScroll', this._DOMMouseScroll, { passive: true })
    }
    document.addEventListener('wheel', this._wheel, { passive: true })
    document.addEventListener('touchmove', this._touchmove, { passive: true })
    document.addEventListener('touchstart', this._touchstart, { passive: true })
  },
  beforeDestroy () {
    if (window.addEventListener) {
      window.removeEventListener('DOMMouseScroll', this._DOMMouseScroll, { passive: true })
    }
    document.removeEventListener('wheel', this._wheel, { passive: true })
    document.removeEventListener('touchmove', this._touchmove, { passive: true })
    document.removeEventListener('touchstart', this._touchstart, { passive: true })
  },
  methods: {
    _DOMMouseScroll (e) {
      if (this.targetId === null) return
      if (window.pageYOffset === this.expandOffset) {
        this.overScroll = e.detail - 20
      }
    },
    _wheel (e) {
      if (this.targetId === null) return
      if (this.scroll === this.expandOffset && window.pageYOffset === this.expandOffset) {
        this.overScroll = -(e.wheelDelta / 20)
      } else if (this.scroll === 0 && window.pageYOffset === 0) {
        const el = document.getElementById(this.targetId)
        this.overScroll = el.scrollTop - 10
      } else {
        if (window.pageYOffset < 0) {
          this.overScroll = window.pageYOffset - 22
        } else {
          this.scroll = window.pageYOffset
        }
      }
    },
    _touchmove (e) {
      if (this.targetId === null) return
      this.touchmouveHandler(e)
    },
    _touchstart (e) {
      if (this.targetId === null) return
      this.touchstartHandler(e)
    },
    setHoverStyle (id, tag) {
      if (this.targetId !== null) return
      this.articleTitleHoverStyle = {
        background: this.getExpandBackgroundColorByTag(tag),
        opacity: 0.9
      }
      this.hoverId = id
    },
    purgeHoverStyle () {
      this.articleTitleHoverStyle = null
      this.hoverId = null
    },
    getBackgroundColorByTag (tag) {
      switch (tag) {
        case 'w3c': return 'rgba(14, 90, 154, .02)'
        case 'wcag': return 'rgba(14, 90, 154, .02)'
        case 'js': return 'rgba(240, 124, 40, .02)'
        case 'go': return 'rgba(55, 93, 169, .02)'
        case 'db': return 'rgba(183, 128, 132, .02)'
        case 'mongo': return 'rgba(18, 130, 76, .02)'
        case 'es': return 'rgba(0, 168, 230, .02)'
        case 'seo': return 'rgba(116, 116, 8, .02)'
        case 'nw': return 'rgba(7, 67, 110, .02)'
        case 'misc': return 'rgba(198, 172, 42, .02)'
        case 'aws': return 'rgba(127, 179, 184, .02)'
        case 'css': return 'rgba(26, 106, 166, .02)'
        case 'ffmpeg': return 'rgba(48, 48, 48, .02)'
      }
    },
    getExpandBackgroundColorByTag (tag) {
      switch (tag) {
        case 'w3c': return 'rgba(14, 90, 154, 1)'
        case 'wcag': return 'rgba(14, 90, 154, 1)'
        case 'js': return 'rgba(240, 124, 40, 1)'
        case 'go': return 'rgba(55, 93, 169, 1)'
        case 'db': return 'rgba(183, 128, 132, 1)'
        case 'mongo': return 'rgba(18, 130, 76, 1)'
        case 'es': return 'rgba(0, 168, 230, 1)'
        case 'seo': return 'rgba(116, 116, 8, 1)'
        case 'nw': return 'rgba(7, 67, 110, 1)'
        case 'misc': return 'rgba(198, 172, 42, 1)'
        case 'aws': return 'rgba(127, 179, 184, 1)'
        case 'css': return 'rgba(26, 106, 166, 1)'
        case 'ffmpeg': return 'rgba(48, 48, 48, 1)'
      }
    },
    getShortCoverNameByTag (tag) {
      switch (tag) {
        case 'w3c': return 'W3C'
        case 'wcag': return 'WCAG'
        case 'js': return 'JS'
        case 'go': return 'GO'
        case 'db': return 'DB'
        case 'mongo': return 'Mongo'
        case 'es': return 'ES'
        case 'seo': return 'SEO'
        case 'nw': return 'NW'
        case 'misc': return 'Misc'
        case 'aws': return 'AWS'
        case 'css': return 'CSS'
        case 'ffmpeg': return 'FFmpeg'
      }
    },
    getCoverNameByTag (tag) {
      switch (tag) {
        case 'w3c': return 'World Wide Web Consortium'
        case 'wcag': return 'Web Content Accessibility Guidelines'
        case 'js': return 'Javascript'
        case 'go': return 'The Go Programming Language'
        case 'db': return 'Database'
        case 'mongo': return 'MongoDB'
        case 'es': return 'Elasticsearch'
        case 'seo': return 'Search Engine Optimization'
        case 'nw': return 'Network'
        case 'misc': return 'Miscellaneous'
        case 'aws': return 'Amazon Web Service'
        case 'css': return 'Cascading Style Sheets'
        case 'ffmpeg': return 'FFmpeg ( FF = fast Fourier ? )'
      }
    },
    sortArticle (str) {
      const sortOrderStr = this.sortOrder
      this.articles = this.metaOrigin.sort(function (a, b) {
        let sorted
        switch (str) {
          case 'tag':
            if (sortOrderStr === 'as') {
              sorted = (a.Tag > b.Tag ? 1 : -1)
            } else {
              sorted = (a.Tag < b.Tag ? 1 : -1)
            }
            break
          case 'cdate':
            sorted = (a.Cdate < b.Cdate ? 1 : -1)
            break
          case 'rdate':
            sorted = (a.Rdate < b.Rdate ? 1 : -1)
            break
        }
        return sorted
      })
      if (str === 'tag') {
        if (sortOrderStr === 'as') {
          this.sortOrder = 'des'
        } else {
          this.sortOrder = 'as'
        }
      }
    },
    toggleColorBackgorund () {
      if (this.black) {
        this.black = false
      } else {
        this.black = true
      }
    },
    touchstartHandler (e) {
      this.touchStartLog.x = e.touches[0].pageX
      this.touchStartLog.y = e.touches[0].pageY
    },
    touchmouveHandler (e) {
      const offset = {}
      offset.x = this.touchStartLog.x - e.touches[0].pageX
      offset.y = this.touchStartLog.y - e.touches[0].pageY
      const el = document.getElementById(this.targetId)
      if (el.scrollTop === 0) {
        this.overScroll = offset.y / 10
      }
    },
    createArticle () {
      this.$router.push({ name: 'Creator' })
    },
    editArticle () {
      this.$router.push({ name: 'Editor', params: { id: this.targetId } })
    },
    scrollExpandPoint () {
      document.body.scrollTop = this.expandPoint
    },
    scrollinit () {
      document.body.scrollTop = 0
    },
    sampler () {
      if (this.targetId === null) return
      if (this.overScroll < -28) {
        this.removeTarget()
      }
    },
    getTargetXY (id) {
      const bel = document.getElementById('b-' + id)
      const clientRect = bel.getBoundingClientRect()
      this.targetTop = clientRect.top + 10
      this.targetLeft = clientRect.left + 10
    },
    initExpand (id) {
      this.expandOffset = window.pageYOffset
      this.expandPoint = document.body.scrollTop
      document.body.style.overflow = 'hidden'
      const bel = document.getElementById('b-' + id)
      bel.style.zIndex = 10
    },
    routerPusher (id) {
      if (this.$route.path !== '/') return
      if (id.length === 24) {
        const About = '5bc06b8cbcecc7a525389443'
        const Notice = '5bc06d91bcecc7a5253897a0'
        const History = '5bb73e30bcecc7a5252b8a14'
        if (id === About) {
          this.$router.push('/', () => {})
          return
        }
        if (id === Notice) {
          this.$router.push('/notice', () => {})
          return
        }
        if (id === History) {
          this.$router.push('/history', () => {})
          return
        }
        this.$router.push('/article/' + id, () => {})
      } else {
        if (id === 'notice' || id === 'history') {
          this.$router.push('/' + id, () => {})
        } else {
          this.$router.push('/tools/' + id, () => {})
        }
      }
    },
    addTarget (id) {
      if (this.processing) return
      if (this.targetId !== null) return
      if (id.length === 24) {
        this.targetMenu = 'article'
      }
      this.purgeHoverStyle()
      this.toolPocessing = true
      this.finAnimate = false
      const that = this
      const el = document.getElementById(id)
      this.routerPusher(id)
      this.getTargetXY(id)
      this.initExpand(id)
      el.style.top = this.targetTop + 'px'
      el.style.left = this.targetLeft + 'px'
      el.style.position = 'fixed'
      this.processing = true
      Velocity(el,
        {
          width: '100%',
          height: '100%',
          top: [50, that.targetTop],
          left: [0, that.targetLeft]
        },
        {
          duration: 200,
          delay: 0,
          easing: 'easeOutQuad',
          begin () {
            that.targetId = id
          },
          complete () {
            el.style.overflow = 'scroll'
            el.style.borderRadius = '0px'
            el.scrollTop = 0
            that.processing = false
            that.toolPocessing = false
            that.finAnimate = true
          }
        }
      )
    },
    removeTarget () {
      if (this.targetId === null) return
      if (this.processing) return
      this.processing = true
      this.toolPocessing = true
      this.targetMenu = null
      this.getTargetXY(this.targetId)
      this.$router.push('/', () => {})
      const that = this
      let elw = 180
      let elh = 180
      if (window.innerWidth <= 1440) {
        elw = 147
        elh = 147
      }
      const el = document.getElementById(this.targetId)
      const bel = document.getElementById('b-' + this.targetId)
      el.style.borderRadius = '10px'
      document.body.scrollTop = this.expandPoint
      Velocity(
        el,
        {
          width: elw,
          height: elh,
          top: that.targetTop,
          left: that.targetLeft,
          translateY: '+=15px'
        },
        {
          duration: 200,
          easing: 'easeInQuad',
          delay: 0,
          begin () {
            that.targetId = null
          },
          complete () {
          }
        }
      )
      Velocity(
        el,
        {
          translateY: '-=15px'
        },
        {
          duration: 200,
          easing: 'linear',
          delay: 0,
          begin () {
          },
          complete () {
            document.body.style.overflow = 'scroll'
            that.processing = false
            that.toolPocessing = false
            bel.style = null
            el.style = null
            that.finAnimate = false
          }
        }
      )
    },
    checkBrowser () {
      let result = 'Unknown'
      const agent = window.navigator.userAgent.toLowerCase()
      const version = window.navigator.appVersion.toLowerCase()
      if (agent.indexOf('msie') > -1) {
        if (version.indexOf('msie 6.') > -1) {
          result = 'IE6'
        } else if (version.indexOf('msie 7.') > -1) {
          result = 'IE7'
        } else if (version.indexOf('msie 8.') > -1) {
          result = 'IE8'
        } else if (version.indexOf('msie 9.') > -1) {
          result = 'IE9'
        } else if (version.indexOf('msie 10.') > -1) {
          result = 'IE10'
        } else {
          result = 'Unknown'
        }
      } else if (agent.indexOf('trident/7') > -1) {
        result = 'IE11'
      } else if (agent.indexOf('edge') > -1) {
        result = 'Edge'
      } else if (agent.indexOf('chrome') > -1) {
        result = 'Chrome'
      } else if (agent.indexOf('safari') > -1) {
        result = 'Safari'
      } else if (agent.indexOf('opera') > -1) {
        result = 'Opera'
      } else if (agent.indexOf('firefox') > -1) {
        result = 'Firefox'
      }
      return result
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.MetaList {
  display:block;
  width:100%;
  height: 100%;
}

.MetaList > span {
  position: static;
  display: flex;
  flex-wrap:wrap;
}

.base-frame {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 200px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
  z-index: 2;
  padding: 10px;
  background: none;
}

.article-frame {
  position: static;
  display: flex;
  flex-direction: column;
  display: flex;
  top:0;
  left:0;
  z-index: 5;
  border-radius: 8px;
  background: #fff;
  width:  180px;
  height: 180px;
  min-width:180px;
  min-height:180px;
  overflow:hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: subpixel-antialiased;
  backface-visibility: hidden;
}

.notExpand {
  cursor: pointer;
  box-shadow: 0px 14px 36px 0px rgba(0,0,0,0.15);
  overflow: hidden;
  backface-visibility: hidden;
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: subpixel-antialiased;
  transition-property: box-shadow;transition-duration: .3s;transition-delay: 0;
}

.notExpand:hover {
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.3);
  /* transform: scale(.98, .98); */
  /* animation: Hover .3s; */
}

.InfoOverFrame {
  background-color: rgba(198, 172, 42, .03);
}

.ToolsOverFrame {
  background-color: rgba(98, 153, 183, .03);
}

.expandf > a > div > .overCover {
  margin-top : 40px;
  color: #fff;
}

.expandf > a > div > .overInfo {
  padding-top : 40px;
}

.expandf > a > div > .overArticle {
  padding-top : 38px;
}

.expandf .InfoOverFrame {
  background-color: rgba(198, 172, 42, 1);
}

.expandf .ToolsOverFrame {
  background-color: rgba(98, 153, 183, 1);
}

.overCover {
  height: 100%;
  min-height: 180px;
  width: 100%;
  min-width: 180px;
  font-size: 2.0rem;
  line-height: 2.5rem;
  font-weight: 300;
  transition-property: color;transition-duration: 1.6s;transition-delay: 450;
  will-change: color;
  backface-visibility: hidden;
}

.overInfo {
  padding-top : 50px;
  color: rgba(168, 142, 42, 1);
}

.overTools {
  color: rgba(98, 153, 183, 1);
  padding-top: 33px;
}

.overArticle {
  padding-top :  48px;
  font-size: 2.6rem;
}

.article-over-frame {
  position: relative;
  text-align: center;
  overflow: hidden;
  z-index:7;
  width:100%;
  height:100%;
  overflow:hidden;
  will-change: background;
  backface-visibility: hidden;
  transition-property: background;transition-duration: 1.4s;transition-delay: 450;
}

.expandf {
  cursor: default;
  overscroll-behavior: none;
  min-height: 230px;
}

.article-title {
  position: absolute;
  width:  100%;
  height: auto;
  padding-top:10px;
  padding-bottom:10px;
  bottom: 0;
  left:0;
  text-align: left;
  background: rgba(0,0,0,.025);
  z-index:8;
  backface-visibility: hidden;
  margin-left: auto;margin-right: auto;
  padding-left:15px;padding-right:15px;
  font-size:0.9rem;line-height:1rem;
  font-weight:400;
  transition-property: background-color;transition-duration: .3s;transition-delay: 0;
}

.notExpandTitle:hover {
  background: rgba(0,0,0,.5);
  color:#fff;
  height:0 auto;
  min-height: 45px;
  font-size: 1.4rem;
  line-height: 1.6rem;
}

.tools-title {
  position: absolute;
  width:  100%;
  padding-top:10px;
  padding-bottom:10px;
  bottom: 0;
  left:0;
  text-align: left;
  background: rgba(0,0,0,.05);
  z-index:8;
  margin-left: auto;margin-right: auto;
  padding-left:15px;padding-right:15px;
  font-size:0.8rem;line-height:1rem;
  font-weight:400;
}

.info-title {
  position: absolute;
  width:  100%;
  height: auto;
  padding-top:10px;
  padding-bottom:10px;
  bottom: 0;
  left:0;
  text-align: left;
  background: rgba(0,0,0,.05);
  z-index:8;
  margin-left: auto;margin-right: auto;
  padding-left:15px;padding-right:15px;
  font-size:0.8rem;line-height:1rem;
  font-weight:400;
}

.expandTitle {
  height:0 auto;
  min-height: 30px;
  font-size: 1.2rem;
  color: #fff;
  background: rgba(0,0,0,.3);
  font-weight:300;
  line-height:1.3rem;
  padding-left:21px;
  padding-right:21px;
}

.ajustTitle {
  font-weight:normal;
  display:block;
  width: 100%;
  margin-top:8px;
  margin-bottom:8px;
  max-width:940px;left:0;right:0;margin-left:auto;margin-right:auto;
}

.ajustToolsTitle {
  font-weight:normal;
  display:block;
  width: 100%;
  margin-top: 8px;
  margin-bottom:8px;
  max-width:1440px;left:0;right:0;margin-left:auto;margin-right:auto;
}

.ajustArticleTitle {
  font-weight:normal;
  display:block;
  width: 100%;
  margin-top:8px;
  margin-bottom:8px;
  max-width:940px;left:0;right:0;margin-left:auto;margin-right:auto;
}

.article-under-frame {
  position: relative;
  width: 100%;
  height: 100%;
  background: none;
  overscroll-behavior: none;
  z-index:-2;
}

.article-content {
  height: 0 auto;
  min-height: 82vh;
  width: 100%;
  background: none;
}

.title {
  background: none;
  width:100%;
  height:50px;
  padding-left:30px;
  margin-top:30px;
}

#spacer {
  width:100%;
  height:30px;
}

.fspacer {
  width: 100%;
}

.sort-menu {
  display: block;
  width: 100vw;
  padding-left: 35px;
  margin-top:0px;
  margin-left: -17px;
}

.f14 {
  font-size: 12px;
}

.meta-title {
  font-size: 2.3rem;
  margin-left: -15px;
  text-shadow: 0 8.36px 8.896px #c4b59d,0 -2px 1px #fff;
}

.notExpandTBCase {
  background : rgba(255,255,255,0.3);
  box-shadow: 0px 8px 30px 0px rgba(0,0,0,0.2);
  transition-property: background;transition-duration: .6s;transition-delay: 0;
}

.expandTBCase {
  background : rgba(255,255,255,1);
  box-shadow: 0px 8px 30px 0px rgba(0,0,0,0.2);
  transition-property: background;transition-duration: .6s;transition-delay: 0;
}

.MainFooter {
  background: rgba(70, 143, 180, .1);
  background: rgba(171, 115,3, .2);

}

.summaryContent {
}

@media screen and (min-width: 1350px) {
  .MainFooter {
    border-radius: 5px;
    box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.2);
    margin-bottom: 30px;
  }
  .fspacer {
    height: calc(6vw - 80px);
  }
}

@media screen and (max-width: 1350px) {
  .base-frame {
    width: 170px;
    height: 170px;
  }

  .article-frame {
    width: 150px;
    height: 150px;
    min-width:150px;
    min-height:150px;
  }

  .article-title {
    font-size:0.65rem;
    font-weight: 450;
    line-height:1.1rem;
    padding-left:10px;padding-right:10px;
  }
  .article-title:hover {
  }

  .tools-title {
    padding-left:10px;padding-right:10px;
    font-size:0.7rem;
  }

  .info-title {
    padding-left:10px;padding-right:10px;
    font-size:0.7rem;
  }

  .cover {
    min-height:150px;
    min-width:150px;
  }

  .article-over-frame {
    min-height:150px;
    min-width:150px;
  }

  .expandTitle {
    font-size:0.95rem;
    padding-left:20px;padding-right:20px;
    line-height:1.1rem;
  }

  .expandf > a > div > .overCover {
    font-size: 2.4rem;
    padding-top : 5px;
  }

  .expandf > a > div > .overInfo {
    font-size: 2.6rem;
    padding-top : 5px;
  }

  .expandf > a > div > .overTools {
    font-size: 2.6rem;
    padding-top : 5px;
  }

  .expandf > a > div > .overArticle {
    font-size: 2.4rem;
  }

  .expandf .article-over-frame {
    min-height:220px;
    padding-top: 20px;
  }

  .meta-title {
    font-size: 2rem;
  }

  .overCover {
    height: 100%;
    min-height: 147px;
    width: 100%;
    min-width: 147px;
    font-size: 1.7rem;
    line-height: 2.2rem;
    font-weight: 300;
    padding-top : 0px;
  }

  .notExpandTitle:hover {
    background: rgba(0,0,0,.5);
    color:#fff;
    height:0 auto;
    min-height: 45px;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  .overInfo {
    padding-top : 40px;
  }

  .overTools {
    padding-top : 23px;
  }

  .overArticle {
    padding-top :  36px;
    font-size: 2rem;
  }

  .fspacer {
    height: 0px;
  }
}

@media screen and (max-width: 768px) {
  .base-frame {
    margin-left: auto;
    margin-right: auto;
  }

  .expandf > a > div > .overArticle {
    font-size: 1.5rem;
  }

  .expandf > a > div > .overCover {
    font-size: 1.5rem;
  }

  .expandf > a > div > .overInfo {
    font-size: 2rem;
  }

  .expandf > a > div > .overTools {
    font-size: 2rem;
  }
}

@keyframes Hover {
  0% {
    transform: scale(1, 1);
    box-shadow: 0px 11px 35px 0px rgba(0,0,0,0.2);
  }
  100% {
    transform: scale(.98, .98);
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.25);
  }
}

</style>
