<template>
  <div class='Whois'>
    <div class='title-msg'>
    ドメインやIPアドレスを調べるツールです。<br />
    利用履歴などとっておりませんのでご自由にお使いください。<br />
    <br />
    <h4>'Domain名'、または'IP'を入力してください。</h4>
    <label
      for='Regular Expression Error'
      class='regError'
      v-bind:class={openRE:regError}
    >** ドメイン名、IPアドレス形式をご確認下さい。  **</label>
    <div class='searchbox'>
      <input
        class='ibox'
        type='text'
        placeholder='abierre.com'
        v-model='searchData.Target'
        v-on:keyup.enter='searchWhois'
        ref='whoisBox'
      >
    </div>
    <h4>Whoisサーバ選択</h4>
    <div class='whois-server'>
      <div>
        <input class='whois-radio' type='radio' id='default' value='' v-model='searchData.Server'>
        <label for='default'>Default ( com.whois-servers.net )</label>
      </div>
      <div>
        <input class='whois-radio' type='radio' id='arin' value='whois.arin.net' v-model='searchData.Server'>
        <label for='arin'>Arin</label>
      </div>
      <div>
        <input class='whois-radio' type='radio' id='ripe' value='whois.ripe.net' v-model='searchData.Server'>
        <label for='ripe'>Ripe</label>
      </div>
      <div>
        <input class='whois-radio' type='radio' id='apnic' value='whois.apnic.net' v-model='searchData.Server'>
        <label for='apnic'>Apnic</label>
      </div>
      <div>
        <input class='whois-radio' type='radio' id='lacnic' value='whois.lacnic.net' v-model='searchData.Server'>
        <label for='lacnic'>Lacnic</label>
      </div>
      <div>
        <input class='whois-radio' type='radio' id='afrinic' value='whois.afrinic.net' v-model='searchData.Server'>
        <label for='afrinic'>Afrinic</label>
      </div>
    </div>
    <button class='btn' v-on:click='searchWhois' :disabled='isDisabled'>Search</button>
    <br />
    <label for=''>( 期待する結果が得られない時は、Whoisサーバを変更してみてください。)</label>
    <br />
    <br />
    <div class='whois-container'>
      <pre>
{{ whois }}
      </pre>
    </div>
    ※ ご注意<br /><br />
    macOS Mojave かつ GoogleChromeをお使いのあなたへ<br />
    ( 2018.10.22時点 )<br /><br />
    OSのバグ？でラジオボタンが表示されないそうです。<br />
    <a href='https://lifehacker.com/how-to-fix-chromes-checkboxes-and-buttons-in-macos-moja-1827398550'>こちら</a>
    の記事によるとズームを100%以外にすると治るそうですが、なんだかなーですね。<br /><br />
    そんな訳でMojaveかつ、Chromeの方はお手数ですが、別のブラウザでお願いいたします。
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Whois',
  metaInfo () {
    return {
      title: 'Whois Search',
      titleTemplate: null,
      link: [
        {
          vmid: 'canonical',
          rel: 'canonical',
          href: 'https://abierre.com/tools/whois'
        },
        {
          vmid: 'alternate',
          rel: 'alternate',
          hreflang: 'ja',
          href: 'https://abierre.com/tools/whois'
        }
      ],
      script: [
        {
          vmid: 'jsonld',
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Article',
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': 'https://abierre.com/tools/whois'
            },
            headline: 'Whois Search',
            articleBody: 'ドメインやIPを検索できるツールです。',
            articleSection: 'Tools',
            image: [
              'https://img.abierre.com/OGP8.png'
            ],
            datePublished: '2018-06-14T21:15:09+09:00',
            dateModified: '2018-06-14T21:15:09+09:00',
            author: {
              '@type': 'Person',
              name: 'ISSIE'
            },
            publisher: {
              '@type': 'Organization',
              name: 'ABIERRE',
              logo: {
                '@type': 'ImageObject',
                url: 'https://img.abierre.com/JsonLdImg.png'
              }
            },
            description: 'ドメインやIPを検索できるツールです。'
          }),
          type: 'application/ld+json'
        }
      ],
      meta: [
        { charset: 'utf-8' },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'Whois Search'
        },
        {
          vmid: 'description',
          property: 'description',
          content: 'ドメインやIPを検索できるツールです。'
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: 'ドメインやIPを検索できるツールです。'
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: 'https://abierre.com/tools/whois'
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'product'
        },
        {
          vmid: 'og:image',
          name: 'og:image',
          content: 'https://img.abierre.com/OGP63.png'
        },
        {
          vmid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          vmid: 'twitter:image',
          name: 'twitter:image',
          content: 'https://img.abierre.com/OGP63.png'
        },
        {
          vmid: 'twitter:text:title',
          name: 'twitter:text:title',
          content: 'Whois Search'
        },
        {
          vmid: 'twitter:title',
          name: 'twitter:title',
          content: 'Whois Search'
        },
        {
          vmid: 'twitter:description',
          name: 'twitter:description',
          content: 'ドメインやIPを検索できるツールです。'
        }
      ]
    }
  },
  data () {
    return {
      axiosconfig: {
        baseURL: 'https://api.abierre.com',
        timeout: 10000,
        headers: {
          'X-ABIERRE-REQID': 'https://abierre.com',
          'Content-Type': 'application/json'
        }
      },
      whois: 'Waiting for your ACTION',
      searchData: {
        Target: '',
        Server: ''
      },
      regError: false,
      isDisabled: 'disabled'
    }
  },
  components: {
  },
  created: function () {
  },
  updated: function () {
  },
  mounted: function () {
    document.dispatchEvent(new Event('x-app-rendered'))
  },
  watch: {
    searchData: {
      handler: function () {
        if (this.searchData.Target === null || this.searchData.Target.length === 0) {
          this.isDisabled = 'disabled'
        } else {
          this.isDisabled = false
        }
      },
      deep: true
    }
  },
  methods: {
    searchWhois: function () {
      this.whois = 'Waiting for your ACTION'
      this.regError = false
      if (!this.checkTarget()) {
        this.regError = true
        this.whois = 'Please modify " YOUR INPUT "'
        return
      }
      this.$refs.whoisBox.blur()
      this.whois = 'Now obtaining ...'
      var _http = axios.create(this.axiosconfig)
      return _http.post('/whois', this.searchData).then((response) => {
        this.whois = response.data
        if (response.status === 204) {
          this.whois = 'No response content'
        }
      })
    },
    checkTarget: function () {
      var _domain = /^([A-Za-z0-9][A-Za-z0-9-]{1,61}[A-Za-z0-9]\.)+([A-Za-z][A-Za-z])+/
      var _ip = /\d+\.\d+\.\d+\.\d+/

      if (_domain.test(this.searchData.Target)) {
        return true
      } else if (_ip.test(this.searchData.Target)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
.Whois {
  display : block;
  padding:20px;
  padding-bottom: 200px;
  text-align: left;
  height: 100%;
  width: 100%;
  max-width: 1440px;left:0;right:0;margin-left:auto;margin-right:auto;
  font-size:60%;
}

.whois-container {
  font-size : 10px;
  width: 100%;
}

a {
text-decoration: none;
}

a:hover {
}

.article-frame {
  display : block;
  width: 30%;
  height: 0 auto;
  margin-top: 10px;
  margin-left: 30px;
  text-align: left;
  width: 100%;
}

.article-content {
  text-align: left;
  border-radius: 3px;
  padding-top: 40px;
  padding-left: 50px;
  padding-right: 30px;
  padding-bottom: 60px;
  width: 96%;
}

.title-msg {
  margin-bottom: 2%;
  font-size:.8rem;
}

.article-id {
  text-align : right;
  padding-right : 30px;
}
.article-cdate {
  text-align : right;
  padding-right : 30px;
}

.article-rdate {
  text-align : right;
  padding-right : 30px;
}

.listmode {
  display:none;
}

.spacer {
height: 50px;
width: 100%;
}

.whois-server {
 padding-left: 10px;
}

.searchbox {
 padding-left: 10px;
 padding-bottom: 20px;
}

.searchbox > input {
 min-width: 300px;
 width: 100%;
 padding: 7px;
 outline : none;
}

.ibox {
  font-size: 2.2rem;
  font-weight: 300;
  line-height: 2.5rem;
  border-top: hidden;
  border-left: hidden;
  border-right: hidden;
  border-bottom: solid 1px rgba(5, 40, 65,1);
   border-radius: 0px;
}

.regError {
display: none;
color : red;

}

.openRE {
 display: block;
}

.whois-radio {
  display: inline-block;
  margin-right: 10px;
}

</style>
