<template>
  <div class='History'>
    <div class='article-content'>
      <vue-markdown :source='article.Content'></vue-markdown>
    </div>

    <div class='editArt' v-on:click='editArticle'>...</div>
    <div class='ajustFooter'><Footer /></div>
  </div>
</template>
<script>
import axios from 'axios'
import VueMarkdown from 'vue-markdown'
import Prism from 'prismjs'
import Footer from '@/components/Footer'
export default {
  name: 'History',
  metaInfo () {
    return {
      title: 'History',
      titleTemplate: null,
      link: [
        {
          vmid: 'canonical',
          rel: 'canonical',
          href: 'https://abierre.com/history'
        },
        {
          vmid: 'alternate',
          rel: 'alternate',
          hreflang: 'ja',
          href: 'https://abierre.com/history'
        }
      ],
      script: [
        {
          vmid: 'jsonld',
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Article',
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': 'https://abierre.com/history'
            },
            headline: 'Notice',
            articleBody: 'ABIERREの各種更新情報を掲載しています。',
            articleSection: 'history',
            image: [
              'https://img.abierre.com/OGP8.png'
            ],
            datePublished: '2018-06-14T21:15:09+09:00',
            dateModified: '2018-06-14T21:15:09+09:00',
            author: {
              '@type': 'Person',
              name: 'ISSIE'
            },
            publisher: {
              '@type': 'Organization',
              name: 'ABIERRE',
              logo: {
                '@type': 'ImageObject',
                url: 'https://img.abierre.com/JsonLdImg.png'
              }
            },
            description: 'ABIERREの各種更新情報を掲載しています'
          }),
          type: 'application/ld+json'
        }
      ],
      meta: [
        { charset: 'utf-8' },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'History'
        },
        {
          vmid: 'description',
          property: 'description',
          content: 'ABIERREの各種更新情報を掲載しています'
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: 'ABIERREの各種更新情報を掲載しています'
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: 'https://abierre.com/history'
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'product'
        },
        {
          vmid: 'og:image',
          name: 'og:image',
          content: 'https://img.abierre.com/OGP63.png'
        },
        {
          vmid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          vmid: 'twitter:image',
          name: 'twitter:image',
          content: 'https://img.abierre.com/OGP63.png'
        },
        {
          vmid: 'twitter:text:title',
          name: 'twitter:text:title',
          content: 'History'
        },
        {
          vmid: 'twitter:title',
          name: 'twitter:title',
          content: 'History'
        },
        {
          vmid: 'twitter:description',
          name: 'twitter:description',
          content: 'ABIERREの各種更新情報を掲載しています'
        }
      ]
    }
  },
  data () {
    return {
      id: '5bb73e30bcecc7a5252b8a14',
      axiosconfig: {
        baseURL: 'https://api.abierre.com',
        timeout: 10000,
        headers: {
          'X-ABIERRE-REQID': 'https://abierre.com',
          'Content-Type': 'application/json'
        }
      },
      article: {
        Title: 'Title',
        Cdate: '0000/00/00',
        Rdate: '0000/00/00',
        Content: '## Still loading ...'
      },
      twitterId: 'abierre_com',
      twitterHeight: '700'
    }
  },
  components: {
    VueMarkdown,
    Footer
  },
  created () {
    this.getArticle()
  },
  updated () {
    this.highlight()
  },
  mounted () {
    document.dispatchEvent(new Event('x-app-rendered'))
  },
  watch: {
    article () {
    },
    $route () {
      this.getArticle()
    }
  },
  beforeRouteEnter () {
    this.getArticle()
  },
  methods: {
    editArticle () {
      this.$router.push({ name: 'Editor', params: { id: this.id } })
    },
    getItemLS (key) {
      return JSON.parse(sessionStorage.getItem(key))
    },
    setItemLS (key, data) {
      var jsonData = JSON.stringify(data)
      sessionStorage.setItem(key, jsonData)
    },
    removeItemLS (key) {
      sessionStorage.removeItem(key)
    },
    highlight () {
      Prism.highlightAll()
    },
    getArticle () {
      var data = this.getItemLS(this.id)
      if (data !== null) {
        this.article = data
      } else {
        var _http = axios.create(this.axiosconfig)
        return _http.get('/article/' + this.id).then((response) => {
          this.article = response.data
          this.highlight()
          this.setItemLS(this.id, response.data)
        })
      }
    },
    reformatDate (d) {
      var _d = new Date(d)
      var DisplayDate = _d.getFullYear() + '.' + ('0' + (_d.getMonth() + 1)).slice(-2) + '.' + ('0' + _d.getDate()).slice(-2)
      return DisplayDate
    }
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
.History {
  display : block;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  height: 100%;
  width: 100%;
  max-width: 980px;left:0;right:0;margin-left:auto;margin-right:auto;
}

.article-content {
  text-align: left;
  border-radius: 3px;
  width:100%;
}

.editArt {
color: #fff;
cursor:pointer;
margin-top:20px;
width:20px;
}

.twitter-widget {
  margin-top:50px;
  margin-left:auto;
  margin-right: auto;
  width: 100%;
  max-width:740px;
  box-shadow: 0px 8px 15px 0px rgba(0,0,0,0.3);
  border-radius: 5px;
  padding:20px;
}

@media screen and (max-width: 768px) {
  P {
    font-size: 0.7rem;
  }
}

</style>
