import Vue from 'vue'
import Router from 'vue-router'
import NotFound from '@/components/NotFound.vue'
import Editor from '@/components/Editor.vue'
import Creator from '@/components/Creator.vue'

import MetaList from '@/components/MetaList.vue'

const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

Vue.use(Router)

const routes = [
  {
    path: '/edit/:id',
    name: 'Editor',
    component: Editor,
    props: true
  },
  {
    path: '/tools/search',
    alias: '/',
    name: 'ArticleSearch',
    component: MetaList
  },
  {
    path: '/',
    name: 'MetaList',
    component: MetaList,
    props: true
  },
  {
    path: '/article/:id',
    alias: '/',
    component: MetaList
  },
  {
    path: '/tools/color',
    alias: '/',
    name: 'ColorSearch',
    component: MetaList
  },
  {
    path: '/tools/contrast',
    alias: '/',
    name: 'ContrastAnalyzer',
    component: MetaList
  },
  {
    path: '/tools/whois',
    alias: '/',
    name: 'Whois',
    component: MetaList
  },
  {
    path: '/tools/qr',
    alias: '/',
    name: 'QRcode',
    component: MetaList
  },
  {
    path: '/tools/base64',
    alias: '/',
    name: 'Base64',
    component: MetaList
  },
  {
    path: '/history',
    alias: '/',
    name: 'History',
    component: MetaList
  },
  {
    path: '/notice',
    alias: '/',
    name: 'Notice',
    component: MetaList
  },
  {
    path: '/contact',
    alias: '/',
    name: 'Contact',
    component: MetaList
  },
  {
    path: '/create',
    name: 'Creator',
    component: Creator
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
