<template>
  <div class="Summary">
    <!--<div class="BIC">test</div>-->
    <div class="article-content">
      <vue-markdown :source="article.Content"></vue-markdown>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import VueMarkdown from 'vue-markdown'
import Prism from 'prismjs'
export default {
  name: 'Summary',
  data () {
    return {
      id: '601a5b7d11fb6366373e66c1',
      axiosconfig: {
        baseURL: 'https://api.abierre.com',
        timeout: 10000,
        headers: {
          'X-ABIERRE-REQID': 'https://abierre.com',
          'Content-Type': 'application/json'
        }
      },
      article: {
        Title: 'Title',
        Cdate: '0000/00/00',
        Rdate: '0000/00/00',
        Content: '## Still loading ...'
      }
    }
  },
  components: {
    VueMarkdown
  },
  created () {
    this.getArticle()
  },
  updated () {
    this.highlight()
  },
  watch: {
    article () {
    },
    '$route' () {
      this.getArticle()
    }
  },
  methods: {
    editArticle () {
      this.$router.push({ name: 'Editor', params: { id: this.id } })
    },
    getItemLS (key) {
      return JSON.parse(sessionStorage.getItem(key))
    },
    setItemLS (key, data) {
      var jsonData = JSON.stringify(data)
      sessionStorage.setItem(key, jsonData)
    },
    removeItemLS (key) {
      sessionStorage.removeItem(key)
    },
    highlight () {
      Prism.highlightAll()
    },
    getArticle () {
      var data = this.getItemLS(this.id)
      if (data !== null) {
        this.article = data
      } else {
        var _http = axios.create(this.axiosconfig)
        return _http.get('/article/' + this.id).then((response) => {
          this.article = response.data
          this.highlight()
          this.setItemLS(this.id, response.data)
        })
      }
    },
    reformatDate (d) {
      var _d = new Date(d)
      var DisplayDate = _d.getFullYear() + '.' + ('0' + (_d.getMonth() + 1)).slice(-2) + '.' + ('0' + _d.getDate()).slice(-2)
      return DisplayDate
    }
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
.Summary {
  display : block;
  text-align: left;
  height: 100%;
  width: 100%;
  margin-top:40px;
}

.article-content {
  text-align: left;
  border-radius: 3px;
  padding-left: 14px;
  padding-right: 30px;
  width:100%;
}

.BIC {
  border-image: url(https://img.abierre.com/Framer.svg) 20 stretch;
  border-style:solid;
  box-sizing:border-box;
  border-width: 20px;
  width: 50px;
  height: 0 auto;
  padding: 0;
  margin: 0;
}

</style>
