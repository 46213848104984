<template>
  <div class="ArticleContent">
    <div class="meta">
      <div class="metaContainer">
        <p>ARTICLE_ID : {{aid}}</p>
        <p v-on:click="editArticle">AUTHOR : ISSIE</p>
        <p>MODIFIED DATE : {{ reformatDate(article.Rdate) }}</p>
        <p>CREATE DATE : {{ reformatDate(article.Cdate) }}</p>
      </div>
    </div>
    <div class="contentBase">
      <vue-markdown
        :source="article.Content"
      ></vue-markdown>
      <div class="ajustFooter">
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import Prism from 'prismjs'
import api from '@/utils/api'
import Footer from '@/components/Footer'
export default {
  name: 'ArticleContent',
  props: ['aid'],
  mixins: [api],
  components: {
    VueMarkdown,
    Footer
  },
  metaInfo () {
    return {
      title: this.article.Title,
      titleTemplate: null,
      link: [
        {
          vmid: 'alternate',
          rel: 'alternate',
          hreflang: 'ja',
          href: 'https://abierre.com/article/' + this.article.Id
        }
      ],
      script: [
        {
          vmid: 'jsonld',
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Article',
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': 'https://abierre.com/article/' + this.article.Id
            },
            headline: this.article.Title,
            articleBody: this.article.Content.substr(0, 130) + '...',
            articleSection: this.article.Tag,
            image: [
              'https://img.abierre.com/OGP63.png'
            ],
            datePublished: this.article.Cdate,
            dateModified: this.article.Rdate,
            author: {
              '@type': 'Person',
              name: 'ISSIE'
            },
            publisher: {
              '@type': 'Organization',
              name: 'ABIERRE',
              logo: {
                '@type': 'ImageObject',
                url: 'https://img.abierre.com/JsonLdImg.png'
              }
            },
            description: this.article.Content.substr(0, 80) + '...'
          }),
          type: 'application/ld+json'
        }
      ],
      meta: [
        { charset: 'utf-8' },
        {
          vmid: 'description',
          property: 'description',
          content: this.article.Content.substr(0, 80) + '...'
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: this.article.Title
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: this.article.Content.substr(0, 80) + '...'
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: 'https://abierre.com/article/' + this.article.Id
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'article'
        },
        {
          vmid: 'og:image',
          name: 'og:image',
          content: 'https://img.abierre.com/OGP63.png'
        },
        {
          vmid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          vmid: 'twitter:image',
          name: 'twitter:image',
          content: 'https://img.abierre.com/OGP63.png'
        },
        {
          vmid: 'twitter:text:title',
          name: 'twitter:text:title',
          content: this.article.Title
        },
        {
          vmid: 'twitter:title',
          name: 'twitter:title',
          content: this.article.Title
        },
        {
          vmid: 'twitter:description',
          name: 'twitter:description',
          content: this.article.Content.substr(0, 80) + '...'
        }
      ]
    }
  },
  data () {
    return {
      article: {
        Content: '# Still Loading ...'
      },
      stDate: null,
      rnDate: null,
      fdDate: null
    }
  },
  created () {
    this.stDate = +new Date() / 1000
    this._getArticle()
  },
  mounted () {
  },
  updated () {
    this.highlight()
  },
  watch: {
    article () {
      this.highlight()
    }
  },
  methods: {
    _getArticle () {
      this.rnDate = +new Date() / 1000
      this.getArticle(this.aid).then((article) => {
        this.article = article
        document.dispatchEvent(new Event('x-app-rendered'))
        this.fdDate = +new Date() / 1000
      })
    },
    highlight () {
      Prism.highlightAll()
      this.$emit('updateHead')
    },
    editArticle () {
      this.$router.push({ name: 'Editor', params: { id: this.aid } })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../assets/MD.css';
.ArticleContent {
  position: absolute;
  width: 100%;
  height: 0 auto;
  background: none;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  top:0;
  left:0;
  overflow-x:hidden;
  -webkit-overflow-scrolling: touch;
}

.meta {
  position:absolute;;
  background:rgba(130,90,2,.1);
  width:100%;
  margin-left: -20px;
  margin-right: -20px;
}

.metaContainer {
  position:relative;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align:left;
  max-width:940px;left:0;right:0;margin-left:auto;margin-right:auto;
  width:100%;
}

.meta p {
  font-size: 0.7rem;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.spacer {
  background: none;
  height:20px;
  width:100%;
}

.contentBase {
  position: relative;
  width:100%;
  max-width:940px;
  height:0 auto;
  left:0;
  right:0;
  margin-top: 115px;
  margin-left: auto;
  margin-right: auto;
}

.ajustFooter {
  width:100%;
  max-width:970px;
  background:none;
  margin-right:auto;
  margin-left:auto;
}

@media screen and (max-width: 970px) {
  .metaContainer {
    padding-left:20px;
  }
  .ajustFooter {
    width:100%;
    max-width:970px;
    background:none;
    margin-right:auto;
    margin-left:auto;
  }
  .ajustFooter > div {
    margin-left:-25px;
  }
}

</style>
