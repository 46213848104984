<template>
  <div class="Creator">
    <header id="global-head">
      <div id="gh-container">
        <router-link to="/">
          <img id="brand-logo" alt="brand-logo" src="https://img.abierre.com/logo_l.svg"/>
        </router-link>
        <div class="gh-menu">
          <div class="gh-inner-menu">
            <img class="expand" src="https://img.abierre.com/expand.svg" alt="expand_editor" v-on:click="callFullScreen">
            <div class="ebtn" v-on:click="loadCache">LoadCache</div>
            <div class="ebtn" v-on:click="createArticle">Create</div>
          </div>
        </div>
      </div>
    </header>
    <div id="md-tools">
      <div class="title-head">Create Article</div>
      <div class="title-frame">
        <label for="">Title</label>
        <input class="ibox" v-bind:class={open400:nullTitle} type="text" aceholder="Title" v-model="article.Title">
      </div>
      <div class="title-frame">
        <label for="">Tag</label>
        <input class="ibox" v-bind:class={open400:nullTag} type="text" aceholder="Title" v-model="article.Tag">
      </div>
      <div class="title-frame">
        <label for="">Token</label>
        <label for="Token-Error" class="msg401" v-bind:class={open401:is401}> ※ INVALID YOUR TOKEN</label>
        <input class="ibox" type="text" aceholder="Title" v-model="axiosconfig.headers['X-AUTH-TOKEN']">
      </div>
    </div>
    <div id="md">
      <div id="md-input-frame" v-bind:class={open400:nullContent}>
        <textarea id="md-input" v-bind:class={open400:nullContent} placeholder="Type here ..." v-model="article.Content">
        </textarea>
      </div>
      <div id="md-result-frame">
        <div id="md-result">
          <vue-markdown :source="article.Content"></vue-markdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import Prism from 'prismjs'
import axios from 'axios'
import Split from 'split.js'
export default {
  name: 'Creator',
  metaInfo: {
    title: 'Create Article',
    titleTemplate: '%s - ABIERRE',
    link: [
      {
        vmid: 'canonical',
        rel: 'canonical',
        href: 'https://abierre.com/create'
      }
    ],
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'og:title',
        property: 'og:title',
        content: 'Create Article - ABIERRE'
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: 'Blog記事の作成を行います。'
      },
      {
        vmid: 'og:url',
        property: 'og:url',
        content: 'https://abierre.com/create'
      },
      {
        vmid: 'og:type',
        property: 'og:type',
        content: 'article'
      },
      {
        vmid: 'twitter:title',
        name: 'twitter:title',
        content: 'Create Article - ABIERRE'
      },
      {
        vmid: 'twitter:description',
        name: 'twitter:description',
        content: 'Blog記事の新規作成を行います。'
      },
      {
        vmid: 'robots',
        name: 'robots',
        content: 'noindex, nofollow'
      }
    ]
  },
  data () {
    return {
      is401: false,
      input_scroll_point: '',
      result_scroll_point: '',
      nullTitle: false,
      nullTag: false,
      nullContent: false,
      article: {
        Title: 'Title',
        Tag: 'Tag',
        Content: 'Content',
        Cdate: '',
        Rdate: '',
        Ddate: 'ISODate("1970-01-01T00:00:00.000Z")'
      },
      axiosconfig: {
        baseURL: 'https://api.abierre.com',
        timeout: 10000,
        headers: {
          'Content-Type': 'application/json',
          'X-AUTH-TOKEN': '',
          'X-ABIERRE-REQID': 'https://abierre.com'
        }
      },
      redirectArticleId: ''
    }
  },
  components: {
    VueMarkdown
  },
  methods: {
    callFullScreen: function () {
      var mdarea = document.getElementById('md')
      if (document.fullscreenEnabled) {
        mdarea.requestFullscreen()
      } else if (document.webkitFullscreenEnabled) {
        mdarea.webkitRequestFullscreen()
      } else if (document.mozFullScreenEnabled) {
        mdarea.mozRequestFullScreen()
      } else if (document.msFullscreenEnabled) {
        mdarea.msRequestFullscreen()
      }
    },
    getISODate: function () {
      var date = new Date()
      var addZero = function (time) {
        return ('0' + time).slice(-2)
      }
      const ISO8601Date = date.getFullYear() + '-' +
        addZero(date.getMonth() + 1) + '-' +
        addZero(date.getDate()) +
        'T' +
        addZero(date.getHours()) + ':' +
        addZero(date.getMinutes()) + ':' +
        addZero(date.getSeconds()) + '+' +
        addZero(date.getTimezoneOffset() / (-60)) + ':00'
      return ISO8601Date
    },
    createArticle: function () {
      this.article.Rdate = this.getISODate()
      this.article.Cdate = this.getISODate()
      var postdata = JSON.stringify(this.article)
      var _http = axios.create(this.axiosconfig)
      return _http.post('/article', postdata).then((res) => {
        this.redirectArticleId = res.data
      }).catch((err) => {
        if (err.response.status === 401) {
          this.is401 = true
        }
        if (err.response.status === 400) {
          this.nullCheck()
        }
      })
    },
    highlight: function () {
      Prism.highlightAll()
    },
    handleInputScroll: function () {
      this.input_scroll_point = document.getElementById('md-input').scrollTop
    },
    handleResultScroll: function () {
      this.result_scroll_point = document.getElementById('md-result').scrollTop
    },
    loadCache: function () {
      var data = localStorage.getItem('creator')
      if (data === null) {
        return false
      } else {
        this.article = JSON.parse(data)
      }
    },
    setCache: function () {
      var jsonData = JSON.stringify(this.article)
      localStorage.setItem('creator', jsonData)
    },
    purgeCache: function () {
      localStorage.removeItem('creator')
    },
    nullCheck: function () {
      if (this.article.Title === '') {
        this.nullTitle = true
      } else {
        this.nullTitle = false
      }
      if (this.article.Tag === '') {
        this.nullTag = true
      } else {
        this.nullTag = false
      }
      if (this.article.Content === '') {
        this.nullContent = true
      } else {
        this.nullContent = false
      }
    }
  },
  mounted: function () {
    if (document.body.style.overflow === 'hidden') {
      document.body.style.overflow = 'scroll'
    }
    document.getElementById('md-input').addEventListener('scroll', this.handleInputScroll)
    document.getElementById('md-result').addEventListener('scroll', this.handleResultScroll)
    Split(['#md-input-frame', '#md-result-frame'], {
      sizes: [30, 70],
      direction: 'horizontal',
      cursor: 'col-resize',
      minSize: 100,
      elementStyle: function (dimension, size, gutterSize) {
        return {
          'flex-basis': 'calc(' + size + '% - ' + gutterSize + 'px)'
        }
      },
      gutterStyle: function (dimension, gutterSize) {
        return {
          'flex-basis': gutterSize + 'px',
          'background-color': '#c16d01',
          'background-image': 'url(https://img.abierre.com/dragpoint.svg)',
          'background-repeat': 'no-repeat'
        }
      }
    })
  },
  watch: {
    redirectArticleId: function () {
      localStorage.clear()
      this.$router.push({ name: 'Article', params: { id: this.redirectArticleId } })
    },
    article: {
      handler: function () {
        this.highlight()
        this.setCache()
        this.nullCheck()
      },
      deep: true
    },
    input_scroll_point: function () {
      document.getElementById('md-result').scrollTop = this.input_scroll_point
    },
    result_scroll_point: function () {
      document.getElementById('md-input').scrollTop = this.result_scroll_point
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.Creator {
  min-height: 100vh;
  height: 100%;
  text-align:center;
  padding-top:80px;
}

.title-head {
  font-size : 30px;
  margin-bottom: 10px;
  margin-left: 10px;
  padding-top: 3%;
  text-align: left;
}

.title-frame {
padding: 5px;
left:0;
text-align:left;
}

#md-tools {
  height: 6%;
  background: #fff;
  vertical-align: middle;
  padding: 10px;
}

#md {
  display : flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: 95vh;
  margin: 20px;
  width: 100% - 20px;
}

#md:-webkit-full-screen {
top:0;left:0;
width: 100vw;
height: 95vh;
}

#md:fullscreen {
top:0;left:0;
width: 100vw;
height: 95vh;
}

#md-input-frame{
}
#md-input {
  display: block;
  height : 95vh;
  width: 100%;
  border : none;
  background : #fff;
  padding : 20px;
  resize: none;
  overflow : scroll;
  border: 1px solid #aaa;
  font-size: 14px;
  min-width: 0px;

}

#md-input:focus {
  outline : none;
}

#md-input-frame > * {
  min-width: 0px;
}

#md-result-frame {
}

#md-result {
  text-align : left;
  height : 95vh;
  width: 100%;
  background : #fff;
  padding : 20px;
  overflow : scroll;
  border: 1px solid #aaa;
}

#md-result > * {
  min-width: 0px;
}

.ibox {
display: block;
padding : 5px;
border-radius: 3px;
border: 1px solid #aaa;
width: 100%;
font-size: 16px;
}

.ibox:focus {
  outline : none;
}

.msg401 {
display: none;
color : #ff4400;
font-size: 1.5rem;
}

.open401 {
display: inline;
}

.open400 {
  outline : #f40;
  border: 1px solid #f40;
  background: rgba(255, 50, 0, .1);
}

.split {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

.split {
    overflow-y: auto;
    overflow-x: hidden;
}

.split, .gutter.gutter-horizontal {
    float: left;
}

.split, .gutter.gutter-horizontal {
    height: 300px;
}

</style>
