<template>
  <div class='Contact'>
    <div class='contactContainer'>
      <p>何かお気づきの点や、ご相談などございましたら、</p>
      <p>以下フォームよりお問い合わせください。</p>
    </div>
    <div class='contactContainer'>
      <InputText
        label='お名前'
        v-model='target.name'
       maxLength=50
      />
      <InputText
        label='ご連絡先 ( Mail, Twitter 等 )'
        v-model='target.contact'
       maxLength=50
      />
      <div class='TextContainer'>
        <TextArea
          label='お問い合わせ内容'
          maxLength=400
          v-model='target.content'
        />
      </div>

      <button class='btn' v-on:click='contact' :disabled='isDisabled' v-if='!isSending'>Send</button>
      <div class='SuccessMsg' v-if='isSending'>送信中 ...</div>
      <div class='SuccessMsg' v-if='!isSending && isSent'>お問い合わせを受け付けました。</div>
      <div class='SuccessMsg' v-if='!isSending && isSent'>回答まで今しばらくお待ちください。</div>

      <div class='ajustFooter'><Footer /></div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import InputText from '@/components/contact/InputText.vue'
import TextArea from '@/components/contact/TextArea.vue'
import Footer from '@/components/Footer'
export default {
  name: 'Contact',
  metaInfo () {
    return {
      title: 'Contact',
      titleTemplate: null,
      link: [
        {
          vmid: 'canonical',
          rel: 'canonical',
          href: 'https://abierre.com/contact'
        },
        {
          vmid: 'alternate',
          rel: 'alternate',
          hreflang: 'ja',
          href: 'https://abierre.com/contact'
        }
      ],
      script: [
        {
          vmid: 'jsonld',
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Article',
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': 'https://abierre.com/contact'
            },
            headline: 'Contact',
            articleBody: 'お問い合わせ',
            articleSection: 'Tools',
            image: [
              'https://img.abierre.com/OGP8.png'
            ],
            datePublished: '2020-11-24T01:00:07+09:00',
            dateModified: '2020-11-24T11:35:07+09:00',
            author: {
              '@type': 'Person',
              name: 'ISSIE'
            },
            publisher: {
              '@type': 'Organization',
              name: 'ABIERRE',
              logo: {
                '@type': 'ImageObject',
                url: 'https://img.abierre.com/JsonLdImg.png'
              }
            },
            description: 'お問い合わせ'
          }),
          type: 'application/ld+json'
        }
      ],
      meta: [
        { charset: 'utf-8' },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'Contact'
        },
        {
          vmid: 'description',
          name: 'description',
          content: 'お問い合わせ'
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: 'お問い合わせ'
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: 'https://abierre.com/contact'
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'product'
        },
        {
          vmid: 'twitter:title',
          name: 'twitter:title',
          content: 'Contact'
        },
        {
          vmid: 'twitter:description',
          name: 'twitter:description',
          content: 'お問い合わせ'
        }
      ]
    }
  },
  data () {
    return {
      target: {
        name: '',
        contact: '',
        content: ''
      },
      isSending: false,
      isSent: false,
      oldTarget: '',
      notFound: false,
      still: false,
      stillmsg: 'Still Loading ...',
      isDisabled: 'disabled',
      axiosconfig: {
        baseURL: 'https://api.abierre.com',
        timeout: 10000,
        headers: {
          'X-ABIERRE-REQID': 'https://abierre.com',
          'Content-Type': 'application/json'
        }
      }
    }
  },
  components: {
    InputText,
    TextArea,
    Footer
  },
  created () {
    this.loadCache()
  },
  updated () {
  },
  mounted () {
  },
  watch: {
    target: {
      handler () {
        this.setCache()
        if (
          this.target.name.length === 0 ||
          this.target.name.length > 51
        ) {
          this.isDisabled = 'disabled'
          return
        }
        if (
          this.target.contact.length === 0 ||
          this.target.contact.length > 51
        ) {
          this.isDisabled = 'disabled'
          return
        }
        if (
          this.target.content.length === 0 ||
          this.target.content.length > 401
        ) {
          this.isDisabled = 'disabled'
          return
        }

        if (this.still) {
          this.isDisabled = 'disabled'
          return
        }

        this.isDisabled = false
      },
      deep: true
    }
  },
  methods: {
    loadCache () {
      const d = localStorage.getItem('contact')
      if (d === null) return
      this.target = JSON.parse(d)
    },
    setCache () {
      const jsonData = JSON.stringify(this.target)
      localStorage.setItem('contact', jsonData)
    },
    purgeCache () {
      localStorage.removeItem('contact')
    },
    contact () {
      this.still = true
      this.isSending = true
      this.isDisabled = 'disabled'
      const t = this.target
      const text = `
> Name
${t.name}


> Contact
${t.contact}


> Content
${t.content}
`
      const postData = {
        Text: text
      }
      var _http = axios.create(this.axiosconfig)
      return _http.post(
        '/contact',
        postData
      ).then((res) => {
        if (res.status === 200) {
          this.still = false
          this.target = {
            name: '',
            contact: '',
            content: ''
          }
          this.purgeCache()
          this.isSending = false
          this.isSent = true
        }
      }).catch((err) => {
        if (err.response.status !== undefined) {
          if (err.response.status === 400) {
            this.still = true
            this.stillmsg = 'Not Found'
            this.isDisabled = false
          }
        }
      })
    }
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
.Contact {
  padding-top:20px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  height: 100%;
  width: 100%;
  max-width: 980px;left:0;right:0;margin-left:auto;margin-right:auto;
  overflow: hidden;
  background: #fff;
}

.contactContainer {
  padding-top: 20px;
}
.contactContainer p {
  font-size: 16px;
}

.TextContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.rsult-box {
  padding: 15px;
  font-size: .8rem;
}

.ibox {
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 100;
  display: block;
  padding : 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: -2px -2px 2px #fafafa inset, 1px 1px 1px 1px #aaccaa33 inset;
  background: linear-gradient(165deg, #aaccaa22, #fff);
  width: 100%;
  outline : none;
  -webkit-appearance: none;
  border-image: none;
}

.ibox:focus {
  background : #fff;
}

.tbox {
  height: 300px;
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 100;
  display: block;
  padding : 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: -2px -2px 2px #fafafa inset, 1px 1px 1px 1px #aaccaa33 inset;
  background: linear-gradient(165deg, #aaccaa22, #fff);
  width: 100%;
  outline : none;
  -webkit-appearance: none;
  border-image: none;

}

.result-frame {
  margin: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  background : #f4f4f4;
  padding: 15px;
  overflow: hidden;
  transition: background 0.2s ease-in-out;
  box-shadow: 0px 3px 30px rgba(0,0,0,.2);
}

.result-frame:hover {
  background : #fff;
}

.result-inner-frame {
  margin: 5px;
  border : 1px solid #ddd;
  border-radius: 5px;
  background : #fffefe;
  padding: 5px;
  height: 0 auto;
  vertical-align: middle;
  overflow: hidden;
}

.result-inner-frame p {
  font-size: .8rem;
}

.result-title {
  font-size: 1.2em;
  line-height: 1.6em;
  border-left: 5px solid #126c82;
  padding-left: 10px;
}
.stillmsg {
  font-size: 1.2em;
  line-height: 1.6em;
  padding-left: 20px;
}

.modInfo {
padding-left: 20px;
}

.SuccessMsg {
  font-size: 18px;
}
</style>
