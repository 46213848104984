<template>
  <div class="Footer">
    <div class="FooterContainer">
      <a href="https://github.com/ABIERRE" target="_blank">
        <img class="footer-icon" alt="github-logo" src="https://img.abierre.com/git-icon.svg"/>
      </a>
      <a href="https://twitter.com/abierre_com" target="_blank">
        <img class="footer-icon" alt="twitter-logo" src="https://img.abierre.com/twitter-icon.svg"/>
      </a>
      <a href="https://www.facebook.com/abierrecom" target="_blank">
        <img class="footer-icon" alt="facebook-logo" src="https://img.abierre.com/facebook-icon.svg"/>
      </a>
      <a href="https://menta.work/plan/1325" target="_blank">
        <img class="footer-icon-menta" alt="menta-logo" src="https://img.abierre.com/menta.png"/>
      </a>
      <div class="version">version: 2.13.23</div>
      <span class="SCajuster">
        <div class="footer-msg1">
        <!--<span class="footer-msg-addr">contact@i.abierre.com</span>-->
        </div>
        <div class="footer-msg">
        © 2019 ABIERRE Information and Communications Technology System
        </div>
      </span>
      <div class="SCajuster"></div>
   </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      w: window.innerWidth
    }
  },
  created: function () {
  },
  mounted: function () {
  },
  watch: {
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.Footer {
  width:100%;
  height:0 auto;
  min-height:40px;
  padding-top:10px;
  padding-left:17px;
  padding-right:15px;
  padding-bottom:0px;
  margin-top: 35px;
  margin-left:auto;
  margin-right:auto;
}

.FooterContainer {
  width:100%;
  height:100%;
  background: none;
  margin-top: 10px;
}

.footer-logo {
  display:inline-block;
  height:50px;
  width: auto;
  margin-top: 14px;
  margin-left: 14px;
}

.footer-icon {
  display:inline-block;
  height:40px;
  width: auto;
  margin-bottom: 6px;
  margin-left: 12px;
}

.footer-icon-menta {
  display:inline-block;
  height:40px;
  width: auto;
  margin-bottom: 6px;
  margin-left: 12px;
  border-radius: 50%;
  border: 3.1px solid #440;
  padding:3px;
  background: #fff;
}

.footer-msg {
  display:inline-block;
  height:40px;
  width: auto;
  margin-top: 14px;
  margin-bottom: 14px;
  margin-left: 14px;
  vertical-align: middle;
  font-size: 1rem;
}

.footer-msg-addr {
  display:inline-block;
  height:40px;
  width: auto;
  margin-left: 10px;
  vertical-align: middle;
  font-size: 0.9rem;
}

.version {
  display: inline-block;
  height: 20px;
  font-size: 10px;
  line-height: 20px;
  margin-top: -40px;
  margin-left: 12px;
  vertical-align: middle;
  text-align: center;
}

@media screen and (max-width: 1350px) {
  .Footer {
    margin-left: -10px;
    width:100vw;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 630px) {
  .footer-msg1 {
    display:inline-block;
    height:40px;
    width: 0 auto;
    margin-top: 5px;
    margin-bottom: -10px;
    margin-left: 10px;
    vertical-align: bottom;
    font-size: 0.7rem;
  }

  .footer-msg {
    display:inline-block;
    height:40px;
    width: 0 auto;
    margin-bottom: 0px;
    margin-left: 0px;
    vertical-align: middle;
    font-size: 0.5rem;
  }

  .SCajuster {
    display: block;
    width:100%;
    margin-bottom:20px;
  }
}

</style>
