<template>
  <div class='QRcode'>
    <div class='title-msg'>今更感ありますが、思いつきで作りました。<br />サーバに保存したりしませんのでご心配なく。</div>
    <div class='searchbox'>
      <h4>メッセージ、URL、メールアドレス、なんでも！</h4>
      <label class='err' :class={openlabel:error}>※いや流石に、文字多すぎっす。</label>
      <input class='ibox' type='text' placeholder='https://abierre.com' v-model='qrStrings' v-on:keyup.enter='createQRcode' ref='qrBox'>
      <button class='btn' v-on:click='createQRcode' :disabled='isDisabledInput'>Create</button>
      <br />
      <br />
    </div>

    <div class='qr-container'>
      <div class='section-title'>QR Code Image</div>
      <img :src='result' alt=''>
      <div class='section-title'>QR Code Raw Data</div>
      <button class=btn v-on:click='copyBase64' :disabled='isDisabled'>Copy to Clipboard</button>
      <pre>
{{ result }}
      </pre>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode'
import copy from 'copy-to-clipboard'
export default {
  name: 'QRcode',
  metaInfo () {
    return {
      title: 'QRcode Creator',
      titleTemplate: null,
      link: [
        {
          vmid: 'canonical',
          rel: 'canonical',
          href: 'https://abierre.com/tools/qr'
        },
        {
          vmid: 'alternate',
          rel: 'alternate',
          hreflang: 'ja',
          href: 'https://abierre.com/tools/qr'
        }
      ],
      script: [
        {
          vmid: 'jsonld',
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Article',
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': 'https://abierre.com/tools/qr'
            },
            headline: 'QR Code Creator',
            articleBody: 'QR Codeを作成するツールです。',
            articleSection: 'Tools',
            image: [
              'https://img.abierre.com/OGP8.png'
            ],
            datePublished: '2018-06-14T21:15:09+09:00',
            dateModified: '2018-06-14T21:15:09+09:00',
            author: {
              '@type': 'Person',
              name: 'ISSIE'
            },
            publisher: {
              '@type': 'Organization',
              name: 'ABIERRE',
              logo: {
                '@type': 'ImageObject',
                url: 'https://img.abierre.com/JsonLdImg.png'
              }
            },
            description: 'QR Codeを作成するツールです。'
          }),
          type: 'application/ld+json'
        }
      ],
      meta: [
        { charset: 'utf-8' },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'QR Code Creator'
        },
        {
          vmid: 'description',
          property: 'description',
          content: 'QR Codeを作成するツールです。'
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: 'QR Codeを作成するツールです。'
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: 'https://abierre.com/tools/qr'
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'product'
        },
        {
          vmid: 'og:image',
          name: 'og:image',
          content: 'https://img.abierre.com/OGP63.png'
        },
        {
          vmid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          vmid: 'twitter:image',
          name: 'twitter:image',
          content: 'https://img.abierre.com/OGP63.png'
        },
        {
          vmid: 'twitter:text:title',
          name: 'twitter:text:title',
          content: 'QR Code Creator'
        },
        {
          vmid: 'twitter:title',
          name: 'twitter:title',
          content: 'QR Code Creator'
        },
        {
          vmid: 'twitter:description',
          name: 'twitter:description',
          content: 'QR Codeを作成するツールです。'
        }
      ]
    }
  },
  data () {
    return {
      qrStrings: null,
      result: null,
      error: false,
      errData: '',
      isDisabled: 'disabled',
      isDisabledInput: 'disabled'
    }
  },
  components: {
  },
  created: function () {
  },
  updated: function () {
  },
  mounted: function () {
    document.dispatchEvent(new Event('x-app-rendered'))
  },
  watch: {
    result: function () {
      if (this.result === null) {
        this.isDisabled = 'disabled'
      } else {
        this.isDisabled = false
      }
    },
    qrStrings: function () {
      if (this.qrStrings === null || this.qrStrings.length === 0) {
        this.isDisabledInput = 'disabled'
      } else {
        this.isDisabledInput = false
      }
    }
  },
  methods: {
    createQRcode: function () {
      if (this.qrStrings === null) return
      this.$refs.qrBox.blur()
      QRCode.toDataURL(this.qrStrings).then(url => {
        this.result = url
      }).catch(err => {
        this.errData = err
        this.error = true
      })
    },
    copyBase64: function () {
      if (this.result === null) return
      copy(this.result)
    }
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
.QRcode {
  display : block;
  padding: 20px;
  padding-bottom: 200px;
  text-align: left;
  height: 100%;
  width: 100%;
  max-width: 1440px;left:0;right:0;margin-left:auto;margin-right:auto;
}

.whois-container {
  font-size : 10px;
  width: 100%;
}

a {
text-decoration: none;
}

a:hover {
}

.qr-frame {
  display : block;
  width: 30%;
  height: 0 auto;
  margin-top: 10px;
  margin-left: 30px;
  text-align: left;
  width: 100%;
}

.qr-container {
  text-align: left;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 30px;
  padding-bottom: 20px;
  border: solid 1px rgba(5, 40, 65,1);
  border-radius: 5px;
  width: 96%;
}

.title-head {
  font-size : 30px;
  margin-bottom: 10px;
  margin-left: 10px;
  padding-top: 3%;
}

.title-msg {
  padding-bottom: 2%;
  font-size:0.8rem;
}

h4 {
  font-size:0.8rem;
}

.section-head {
 font-size : 20px;
 padding: 2%;
}

input:focus {
outline: none;
}

input {
display: block;
width: 96%;
padding:5px;
margin-bottom: 1%;
border: 1px solid #aaa;
border-radius : 0px;
}

.err {
  display: none;
  color: #f40;
  font-size: 1.5rem;
}

.openlabel {
  display: block;
}

.ibox {
  font-size: 2.2rem;
  font-weight: 300;
  line-height: 2.5rem;
  border-radius : 0px;
  border-top: hidden;
  border-left: hidden;
  border-right: hidden;
  border-bottom: solid 1px rgba(5, 40, 65,1);
}
</style>
