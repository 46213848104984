import axios from 'axios'

const axiosConfig = {
  baseURL: 'https://api.abierre.com',
  timeout: 20000,
  headers: {
    'X-ABIERRE-REQID': 'https://abierre.com',
    'Content-Type': 'application/json'
  }
}

const _http = axios.create(axiosConfig)

const checkStatus = function (response, key) {
  if (response.status === 200) {
    putSessionStorage(key, response.data)
    return response.data
  }
  if (response.status === 401) {
    return 401
  }
  if (response.status === 404) {
    return 404
  }
}

const getSessionStorage = (key) => {
  return JSON.parse(sessionStorage.getItem(key))
}

const putSessionStorage = (key, data) => {
  var jsonData = JSON.stringify(data)
  sessionStorage.setItem(key, jsonData)
  return true
}

// const getSessionStorageLength = () => {
//   return sessionStorage.length
// }

// const removeSessionStorage = (key) => {
//   sessionStorage.removeItem(key)
//   return true
// }
// const purgeSessionStorage = () => {
//   sessionStorage.clear()
//   return true
// }
// const getLocalStorage = (key) => {
//   return JSON.parse(localStorage.getItem(key))
// }
// const putLocalStorage = (key, data) => {
//   var jsonData = JSON.stringify(data)
//   localStorage.setItem(key, jsonData)
//   return true
// }
// const getLocalStorageLength = () => {
//   return localStorage.length
// }
// const removeLocalStorage = (key) => {
//   localStorage.removeItem(key)
//   return true
// }
// const purgeLocalStorage = () => {
//   localStorage.clear()
//   return true
// }

export default {
  methods: {
    reformatDate: function (d) {
      var _d = new Date(d)
      var DisplayDate = _d.getFullYear() + '.' + ('0' + (_d.getMonth() + 1)).slice(-2) + '.' + ('0' + _d.getDate()).slice(-2)
      return DisplayDate
    },
    searchArticle: async (searchWord) => {
      var postData = {
        target: searchWord
      }
      var response = await _http.post('/article/search', postData)
      return checkStatus(response, searchWord)
    },
    getArticle: async (id) => {
      var sessionData = getSessionStorage(id)
      if (sessionData !== null) {
        return sessionData
      } else {
        var response = await _http.get('/article/' + id, {})
        return checkStatus(response, id)
      }
    },
    searchNote: async (searchWord) => {
      var postData = {
        target: searchWord
      }
      var response = await _http.post('/note/search', postData)
      return checkStatus(response, searchWord)
    },
    getNote: async (id) => {
      var sessionData = getSessionStorage('note-' + id)
      if (sessionData !== null) {
        return sessionData
      } else {
        var response = await _http.get('/note/' + id, {})
        return checkStatus(response, 'note-' + id)
      }
    },
    getImage: async (url) => {
      const res = await axios.get(url)
      return res.data
    }
  }
}
