<template>
  <div class="ColorSearch">
    <div class="title-msg">
      <li></li><br />
      配色だったりを調べるのに活用ください!<br />
      ただ遊ぶだけでも楽しいのですが、集中しすぎると補色残像で目をやられますのでご注意ください。<br />
      ちなみに、
      <span style="padding:3px 5px 3px 5px;border-radius:2px;" :style="[{background : colors.rgbatxt}, {color : calcFontColorByContrastRatio(colors.rgba)} ]">丸い要素</span>はクリックできますので、ぽちぽちしてみてください。
    </div>
    <div class="color-container" :class="{ darken : black }">
    <sketch-picker class="ajast" v-model="colors" @nput="colors"/>
      <div class="cssgen">
        <pre>
.sample {
  color: {{ colors.rgbatxt }};
  color: {{ colors.hex }};
  color: hsl({{ colors.hsl.h }}, {{ colors.hsl.s }}, {{ colors.hsl.l }});
  background: {{ colors.rgbatxt }};
  background: {{ colors.hex }};
  background: hsl({{ colors.hsl.h }}, {{ colors.hsl.s }}, {{ colors.hsl.l }});
  border: 1px solid {{ colors.hex }};
}

style="color: {{ colors.rgbatxt}};"
style="color: {{ colors.hex}};"
style="background: {{ colors.rgbatxt}};"
style="background: {{ colors.hex}};"
style="border: 1px solid {{ colors.hex }};"
        </pre>
      </div>
      <div class="section-title" :class="{ blackTitle : black }">
        Summary
      </div>

      <div class="result-nbg" :style="{background : colors.rgbatxt}">
        <span :style="{color: calcFontColorByContrastRatio(colors.rgba)}">Target<br />Color<br />{{ colors.hex }}</span>
      </div>

      <div class="result-bg" :style="{background : complementary_colors.rgbatxt}" v-on:click="pickColor(complementary_colors.hex)">
        <span :style="{color: calcFontColorByContrastRatio(complementary_colors.rgba)}"><span class="F13">補色</span>{{ complementary_colors.hex }}</span>
      </div>

      <div class="result-bg" :style="{background : inverse_colors.rgbatxt}" v-on:click="pickColor(inverse_colors.hex)">
        <span :style="{color: calcFontColorByContrastRatio(inverse_colors.rgba)}"><span class="F13">反転色</span>{{ inverse_colors.hex }}</span>
      </div>

      <div class="section-title" :class="{ blackTitle : black }">
        色相 - Hue -
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + ajustAngle(colors.hsl.h - 30)  + ', ' + colors.hsl.s + '%, ' + colors.hsl.l  + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(ajustAngle(colors.hsl.h - 30), colors.hsl.s, colors.hsl.l))">
       <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(ajustAngle(colors.hsl.h - 30), colors.hsl.s, colors.hsl.l))}"> <span class="degF13">-30&deg;</span>{{ hsl2hex(ajustAngle(colors.hsl.h - 30), colors.hsl.s, colors.hsl.l) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + ajustAngle(colors.hsl.h - 20)  + ', ' + colors.hsl.s + '%, ' + colors.hsl.l  + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(ajustAngle(colors.hsl.h - 20), colors.hsl.s, colors.hsl.l))">
       <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(ajustAngle(colors.hsl.h - 20), colors.hsl.s, colors.hsl.l))}"> <span class="degF13">-20&deg;</span>{{ hsl2hex(ajustAngle(colors.hsl.h - 20), colors.hsl.s, colors.hsl.l) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + ajustAngle(colors.hsl.h - 10)  + ', ' + colors.hsl.s + '%, ' + colors.hsl.l  + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(ajustAngle(colors.hsl.h - 10), colors.hsl.s, colors.hsl.l))">
       <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(ajustAngle(colors.hsl.h - 10), colors.hsl.s, colors.hsl.l))}"> <span class="degF13">-10&deg;</span>{{ hsl2hex(ajustAngle(colors.hsl.h - 10), colors.hsl.s, colors.hsl.l) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + ajustAngle(colors.hsl.h - 5)  + ', ' + colors.hsl.s + '%, ' + colors.hsl.l  + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(ajustAngle(colors.hsl.h - 5), colors.hsl.s, colors.hsl.l))">
       <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(ajustAngle(colors.hsl.h - 5), colors.hsl.s, colors.hsl.l))}"> <span class="degF13">- 5&deg;</span>{{ hsl2hex(ajustAngle(colors.hsl.h - 5), colors.hsl.s, colors.hsl.l) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + ajustAngle(colors.hsl.h - 3)  + ', ' + colors.hsl.s + '%, ' + colors.hsl.l  + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(ajustAngle(colors.hsl.h - 3), colors.hsl.s, colors.hsl.l))">
       <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(ajustAngle(colors.hsl.h - 3), colors.hsl.s, colors.hsl.l))}"> <span class="degF13">- 3&deg;</span>{{ hsl2hex(ajustAngle(colors.hsl.h - 3), colors.hsl.s, colors.hsl.l) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + ajustAngle(colors.hsl.h - 1)  + ', ' + colors.hsl.s + '%, ' + colors.hsl.l  + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(ajustAngle(colors.hsl.h - 1), colors.hsl.s, colors.hsl.l))">
       <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(ajustAngle(colors.hsl.h - 1), colors.hsl.s, colors.hsl.l))}"> <span class="degF13">- 1&deg;</span>{{ hsl2hex(ajustAngle(colors.hsl.h - 1), colors.hsl.s, colors.hsl.l) }}</span>
      </div>

      <div class="result-nbg" :style="{background : 'hsla(' + colors.hsl.h  + ', ' + colors.hsl.s + '%, ' + (colors.hsl.l - 0) + '%, ' + colors.rgba.a + ')'}">
        <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, colors.hsl.s, colors.hsl.l))}"><span class="degF13">&nbsp;0&deg;</span>{{ colors.hex }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + ajustAngle(colors.hsl.h + 1)  + ', ' + colors.hsl.s + '%, ' + colors.hsl.l  + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(ajustAngle(colors.hsl.h + 1), colors.hsl.s, colors.hsl.l))">
       <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(ajustAngle(colors.hsl.h + 1), colors.hsl.s, colors.hsl.l))}"> <span class="degF13">+ 1&deg;</span>{{ hsl2hex(ajustAngle(colors.hsl.h + 10), colors.hsl.s, colors.hsl.l) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + ajustAngle(colors.hsl.h + 3)  + ', ' + colors.hsl.s + '%, ' + colors.hsl.l  + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(ajustAngle(colors.hsl.h + 3), colors.hsl.s, colors.hsl.l))">
       <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(ajustAngle(colors.hsl.h + 3), colors.hsl.s, colors.hsl.l))}"> <span class="degF13">+ 3&deg;</span>{{ hsl2hex(ajustAngle(colors.hsl.h + 20), colors.hsl.s, colors.hsl.l) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + ajustAngle(colors.hsl.h + 5)  + ', ' + colors.hsl.s + '%, ' + colors.hsl.l  + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(ajustAngle(colors.hsl.h + 5), colors.hsl.s, colors.hsl.l))">
       <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(ajustAngle(colors.hsl.h + 5), colors.hsl.s, colors.hsl.l))}"> <span class="degF13">+ 5&deg;</span>{{ hsl2hex(ajustAngle(colors.hsl.h + 5), colors.hsl.s, colors.hsl.l) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + ajustAngle(colors.hsl.h + 10)  + ', ' + colors.hsl.s + '%, ' + colors.hsl.l  + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(ajustAngle(colors.hsl.h + 10), colors.hsl.s, colors.hsl.l))">
       <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(ajustAngle(colors.hsl.h + 10), colors.hsl.s, colors.hsl.l))}"> <span class="degF13">+10&deg;</span>{{ hsl2hex(ajustAngle(colors.hsl.h + 10), colors.hsl.s, colors.hsl.l) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + ajustAngle(colors.hsl.h + 20)  + ', ' + colors.hsl.s + '%, ' + colors.hsl.l  + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(ajustAngle(colors.hsl.h + 20), colors.hsl.s, colors.hsl.l))">
       <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(ajustAngle(colors.hsl.h + 20), colors.hsl.s, colors.hsl.l))}"> <span class="degF13">+20&deg;</span>{{ hsl2hex(ajustAngle(colors.hsl.h + 20), colors.hsl.s, colors.hsl.l) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + ajustAngle(colors.hsl.h + 30)  + ', ' + colors.hsl.s + '%, ' + colors.hsl.l  + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(ajustAngle(colors.hsl.h + 30), colors.hsl.s, colors.hsl.l))">
       <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(ajustAngle(colors.hsl.h + 30), colors.hsl.s, colors.hsl.l))}"> <span class="degF13">+30&deg;</span>{{ hsl2hex(ajustAngle(colors.hsl.h + 30), colors.hsl.s, colors.hsl.l) }}</span>
      </div>

      <div class="section-title" :class="{ blackTitle : black }">
        輝度 - Lightness -
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + colors.hsl.h + ', ' + colors.hsl.s + '%, ' + ( colors.hsl.l + 30 ) + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(colors.hsl.h, colors.hsl.s, (colors.hsl.l + 30)))">
        <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, colors.hsl.s, (colors.hsl.l + 30)))}"> <span class="F13">+30</span>{{ hsl2hex(colors.hsl.h, colors.hsl.s, (colors.hsl.l + 30)) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + colors.hsl.h  + ', ' + colors.hsl.s + '%, ' + (colors.hsl.l + 20) + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(colors.hsl.h, colors.hsl.s, (colors.hsl.l + 20)))">
       <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, colors.hsl.s, (colors.hsl.l + 20)))}">  <span class="F13">+20</span>{{ hsl2hex(colors.hsl.h, colors.hsl.s, (colors.hsl.l + 20)) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + colors.hsl.h  + ', ' + colors.hsl.s + '%, ' + (colors.hsl.l + 10) + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(colors.hsl.h, colors.hsl.s, (colors.hsl.l + 10)))">
       <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, colors.hsl.s, (colors.hsl.l + 10)))}">  <span class="F13">+10</span>{{ hsl2hex(colors.hsl.h, colors.hsl.s, (colors.hsl.l + 10)) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + colors.hsl.h  + ', ' + colors.hsl.s + '%, ' + (colors.hsl.l + 5) + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(colors.hsl.h, colors.hsl.s, (colors.hsl.l + 5)))">
       <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, colors.hsl.s, (colors.hsl.l + 5)))}">  <span class="F13">+ 5</span>{{ hsl2hex(colors.hsl.h, colors.hsl.s, (colors.hsl.l + 5)) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + colors.hsl.h + ', ' + colors.hsl.s + '%, ' + ( colors.hsl.l + 3 ) + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(colors.hsl.h, colors.hsl.s, (colors.hsl.l + 3)))">
        <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, colors.hsl.s, (colors.hsl.l + 3)))}"> <span class="F13">+ 3</span>{{ hsl2hex(colors.hsl.h, colors.hsl.s, (colors.hsl.l + 3)) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + colors.hsl.h + ', ' + colors.hsl.s + '%, ' + ( colors.hsl.l + 1 ) + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(colors.hsl.h, colors.hsl.s, (colors.hsl.l + 1)))">
        <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, colors.hsl.s, (colors.hsl.l + 1)))}"> <span class="F13">+ 1</span>{{ hsl2hex(colors.hsl.h, colors.hsl.s, (colors.hsl.l + 1)) }}</span>
      </div>

      <div class="result-nbg" :style="{background : 'hsla(' + colors.hsl.h  + ', ' + colors.hsl.s + '%, ' + (colors.hsl.l - 0) + '%, ' + colors.rgba.a + ')'}">
        <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, colors.hsl.s, colors.hsl.l))}"><span class="F13">0</span>{{ colors.hex }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + colors.hsl.h  + ', ' + colors.hsl.s + '%, ' + (colors.hsl.l - 1) + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(colors.hsl.h, colors.hsl.s, (colors.hsl.l - 1)))">
       <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, colors.hsl.s, (colors.hsl.l - 1)))}"> <span class="F13">- 1</span>{{ hsl2hex(colors.hsl.h, colors.hsl.s, (colors.hsl.l - 1)) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + colors.hsl.h  + ', ' + colors.hsl.s + '%, ' + (colors.hsl.l - 3) + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(colors.hsl.h, colors.hsl.s, (colors.hsl.l - 3)))">
       <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, colors.hsl.s, (colors.hsl.l - 3)))}"> <span class="F13">- 3</span>{{ hsl2hex(colors.hsl.h, colors.hsl.s, (colors.hsl.l - 3)) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + colors.hsl.h  + ', ' + colors.hsl.s + '%, ' + (colors.hsl.l - 5) + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(colors.hsl.h, colors.hsl.s, (colors.hsl.l - 5)))">
       <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, colors.hsl.s, (colors.hsl.l - 5)))}"> <span class="F13">- 5</span>{{ hsl2hex(colors.hsl.h, colors.hsl.s, (colors.hsl.l - 5)) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + colors.hsl.h  + ', ' + colors.hsl.s + '%, ' + (colors.hsl.l - 10) + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(colors.hsl.h, colors.hsl.s, (colors.hsl.l - 10)))">
       <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, colors.hsl.s, (colors.hsl.l - 10)))}"> <span class="F13">-10</span>{{ hsl2hex(colors.hsl.h, colors.hsl.s, (colors.hsl.l - 10)) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + colors.hsl.h  + ', ' + colors.hsl.s + '%, ' + (colors.hsl.l - 20) + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(colors.hsl.h, colors.hsl.s, (colors.hsl.l - 20)))">
       <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, colors.hsl.s, (colors.hsl.l - 20)))}"> <span class="F13">-20</span>{{ hsl2hex(colors.hsl.h, colors.hsl.s, (colors.hsl.l - 20)) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + colors.hsl.h  + ', ' + colors.hsl.s + '%, ' + (colors.hsl.l - 30) + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(colors.hsl.h, colors.hsl.s, (colors.hsl.l - 30)))">
       <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, colors.hsl.s, (colors.hsl.l - 30)))}"> <span class="F13">-30</span>{{ hsl2hex(colors.hsl.h, colors.hsl.s, (colors.hsl.l - 30)) }}</span>
      </div>

      <div class="section-title" :class="{ blackTitle : black }">
        彩度 - Saturation -
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + colors.hsl.h + ', ' + (colors.hsl.s + 30) + '%, ' + colors.hsl.l + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(colors.hsl.h, (colors.hsl.s + 30), colors.hsl.l))">
        <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, (colors.hsl.s + 30), colors.hsl.l))}"> <span class="F13">+30</span>{{ hsl2hex(colors.hsl.h, (colors.hsl.s + 30), colors.hsl.l) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + colors.hsl.h  + ', ' + (colors.hsl.s + 20) + '%, ' + colors.hsl.l + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(colors.hsl.h, (colors.hsl.s + 20), colors.hsl.l))">
        <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, (colors.hsl.s + 20), colors.hsl.l))}"> <span class="F13">+20</span>{{ hsl2hex(colors.hsl.h, (colors.hsl.s + 15), colors.hsl.l) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + colors.hsl.h  + ', ' + (colors.hsl.s + 10) + '%, ' + colors.hsl.l + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(colors.hsl.h, (colors.hsl.s + 10), colors.hsl.l))">
        <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, (colors.hsl.s + 10), colors.hsl.l))}"> <span class="F13">+10</span>{{ hsl2hex(colors.hsl.h, (colors.hsl.s + 10), colors.hsl.l) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + colors.hsl.h  + ', ' + (colors.hsl.s + 5) + '%, ' + colors.hsl.l + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(colors.hsl.h, (colors.hsl.s + 5), colors.hsl.l))">
        <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, (colors.hsl.s + 5), colors.hsl.l))}"> <span class="F13">+ 5</span>{{ hsl2hex(colors.hsl.h, (colors.hsl.s + 5), colors.hsl.l) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + colors.hsl.h + ', ' + (colors.hsl.s + 3) + '%, ' + colors.hsl.l + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(colors.hsl.h, (colors.hsl.s + 3), colors.hsl.l))">
        <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, (colors.hsl.s + 3), colors.hsl.l))}"> <span class="F13">+ 3</span>{{ hsl2hex(colors.hsl.h, (colors.hsl.s + 3), colors.hsl.l) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + colors.hsl.h + ', ' + (colors.hsl.s + 1) + '%, ' + colors.hsl.l + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(colors.hsl.h, (colors.hsl.s + 1), colors.hsl.l))">
        <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, (colors.hsl.s + 1), colors.hsl.l))}"> <span class="F13">+ 1</span>{{ hsl2hex(colors.hsl.h, (colors.hsl.s + 1), colors.hsl.l) }}</span>
      </div>

      <div class="result-nbg" :style="{background : 'hsla(' + colors.hsl.h  + ', ' + colors.hsl.s + '%, ' + colors.hsl.l + '%, ' + colors.rgba.a + ')'}">
        <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, colors.hsl.s, colors.hsl.l))}"><span class="F13">0</span>{{ colors.hex }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + colors.hsl.h  + ', ' + (colors.hsl.s - 1) + '%, ' + colors.hsl.l + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(colors.hsl.h, (colors.hsl.s - 1), colors.hsl.l))">
        <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, (colors.hsl.s - 1), colors.hsl.l))}"> <span class="F13">- 1</span>{{ hsl2hex(colors.hsl.h, (colors.hsl.s - 1), colors.hsl.l) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + colors.hsl.h  + ', ' + (colors.hsl.s - 3) + '%, ' + colors.hsl.l + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(colors.hsl.h, (colors.hsl.s - 3), colors.hsl.l))">
        <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, (colors.hsl.s - 3), colors.hsl.l))}"> <span class="F13">- 3</span>{{ hsl2hex(colors.hsl.h, (colors.hsl.s - 3), colors.hsl.l) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + colors.hsl.h  + ', ' + (colors.hsl.s - 5) + '%, ' + colors.hsl.l + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(colors.hsl.h, (colors.hsl.s - 5), colors.hsl.l))">
        <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, (colors.hsl.s - 5), colors.hsl.l))}"> <span class="F13">- 5</span>{{ hsl2hex(colors.hsl.h, (colors.hsl.s - 5), colors.hsl.l) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + colors.hsl.h  + ', ' + (colors.hsl.s - 10) + '%, ' + colors.hsl.l + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(colors.hsl.h, (colors.hsl.s - 10), colors.hsl.l))">
        <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, (colors.hsl.s - 10), colors.hsl.l))}"> <span class="F13">-10</span>{{ hsl2hex(colors.hsl.h, (colors.hsl.s - 10), colors.hsl.l) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + colors.hsl.h  + ', ' + (colors.hsl.s - 20) + '%, ' + colors.hsl.l + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(colors.hsl.h, (colors.hsl.s - 20), colors.hsl.l))">
        <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, (colors.hsl.s - 20), colors.hsl.l))}"> <span class="F13">-20</span>{{ hsl2hex(colors.hsl.h, (colors.hsl.s - 20), colors.hsl.l) }}</span>
      </div>

      <div class="result-bg" :style="{background : 'hsla(' + colors.hsl.h  + ', ' + (colors.hsl.s - 30) + '%, ' + colors.hsl.l + '%, ' + colors.rgba.a + ')'}" v-on:click="pickColor(hsl2hex(colors.hsl.h, (colors.hsl.s - 30), colors.hsl.l))">
        <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, (colors.hsl.s - 30), colors.hsl.l))}"> <span class="F13">-30</span>{{ hsl2hex(colors.hsl.h, (colors.hsl.s - 30), colors.hsl.l) }}</span>
      </div>

      <div class="section-title" :class="{ blackTitle : black }">
        背景色としてのコントラスト比とサンプル
      </div>
      <div class="result-cover" :style="{background : 'hsla(' + colors.hsl.h  + ', ' + colors.hsl.s + '%, ' + colors.hsl.l + '%, ' + colors.rgba.a + ')'}">
        <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, colors.hsl.s, colors.hsl.l))}">
          <p class="marginAjuster">
          <span class="F14px">General Text Size / 標準テキストサイズ ( 14px )</span>
          <span class="F14px">=> Level: {{ judgeContraRatioAsBackground(false) }}</span>
          </p>
        </span>
        <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, colors.hsl.s, colors.hsl.l))}">
          <p class="marginAjuster">
          <span class="Fb18pt">日本語(18pt)</span>
          <span class="Fb14pt">Alphabet(14pt)</span>
          <span class="Fb14pt">=> Level: {{ judgeContraRatioAsBackground(true) }}</span>
          </p>
          <p class="marginAjuster">
          <span class="F22pt">日本語(22pt)</span>
          <span class="F18pt">Alphabet(18pt)</span>
          <span class="F18pt">=> Level: {{ judgeContraRatioAsBackground(true) }}</span>
          </p>
        </span>
        <span :style="{color: calcFontColorByContrastRatio(hsl2rgb(colors.hsl.h, colors.hsl.s, colors.hsl.l))}">
          ContrasRatio: {{ calcContrastRatioAsBackground(hsl2rgb(colors.hsl.h, colors.hsl.s, colors.hsl.l)) }}
        </span>
      </div>

      <div class="section-title" :class="{ blackTitle : black }">
        文字色としてのコントラスト比とサンプル
      </div>

      <div class="result-cover" :style="{border: '2px solid ' + colors.hex}">
        <span :style="{color: colors.hex}">
          <p class="marginAjuster">
            <span class="F14px">General Text Size / 標準テキストサイズ ( 14px )</span>
            <span class="F14px">=&gt; Level: {{ judgeContraRatioAsFront(false) }}</span>
          </p>

          <p class="marginAjuster">
            <span class="Fb18pt">日本語(18pt)</span>
            <span class="Fb14pt">Alphabet(14pt)</span>
            <span class="Fb14pt">=&gt; Level: {{ judgeContraRatioAsFront(true) }}</span>
          </p>
          <p class="marginAjuster">
            <span class="F22pt">日本語(22pt)</span>
            <span class="F18pt">Alphabet(18pt)</span>
            <span class="F18pt">=&gt; Level: {{ judgeContraRatioAsFront(true) }}</span>
          </p>

        </span>
        <span :style="{color: colors.hex}">ContrasRatio: {{ calcContrastRatio(hsl2rgb(colors.hsl.h, colors.hsl.s, colors.hsl.l)) }}</span>
      </div>

    </div>
  </div>
</template>

<script>
import { Sketch } from 'vue-color'
export default {
  name: 'ColorSearch',
  props: {
    black: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  components: {
    'sketch-picker': Sketch
  },
  metaInfo () {
    return {
      title: 'Color Search',
      titleTemplate: null,
      link: [
        {
          vmid: 'canonical',
          rel: 'canonical',
          href: 'https://abierre.com/tools/color'
        },
        {
          vmid: 'alternate',
          rel: 'alternate',
          hreflang: 'ja',
          href: 'https://abierre.com/tools/color'
        }
      ],
      script: [
        {
          vmid: 'jsonld',
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Article',
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': 'https://abierre.com/tools/color'
            },
            headline: 'Color Search',
            articleBody: '配色を考える際の参考になればと思い作ったツールです。',
            articleSection: 'Tools',
            image: [
              'https://img.abierre.com/OGP8.png'
            ],
            datePublished: '2018-06-04T01:19:09+09:00',
            dateModified: '2018-06-04T01:19:09+09:00',
            author: {
              '@type': 'Person',
              name: 'ISSIE'
            },
            publisher: {
              '@type': 'Organization',
              name: 'ABIERRE',
              logo: {
                '@type': 'ImageObject',
                url: 'https://img.abierre.com/JsonLdImg.png'
              }
            },
            description: '配色を考える際の参考になればと思い作ったツールです。'
          }),
          type: 'application/ld+json'
        }
      ],
      meta: [
        { charset: 'utf-8' },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'Color Search'
        },
        {
          vmid: 'description',
          name: 'description',
          content: '配色を考える際の参考になればと思い作ったツールです。'
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: '配色を考える際の参考になればと思い作ったツールです。'
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: 'https://abierre.com/tools/color'
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'product'
        },
        {
          vmid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          vmid: 'twitter:image',
          name: 'twitter:image',
          content: 'https://img.abierre.com/OGP63.png'
        },
        {
          vmid: 'twitter:text:title',
          name: 'twitter:text:title',
          content: 'Color Search'
        },
        {
          vmid: 'twitter:title',
          name: 'twitter:title',
          content: 'Color Search'
        },
        {
          vmid: 'twitter:description',
          name: 'twitter:description',
          content: '配色を考える際の参考になればと思い作ったツールです。'
        }
      ]
    }
  },
  data () {
    return {
      colors: {
        hex: '#55B6B9',
        rgba: { r: 85, g: 181, b: 185, a: 1 },
        hsl: { h: 182, s: 42, l: 53 },
        rgbatxt: 'rbga(85,181,185,1)',
        a: 1
      },
      complementary_colors: {
        hex: '',
        rgba: {},
        rgbatxt: '',
        a: 1
      },
      inverse_colors: {
        hex: '',
        rgba: {},
        rgbatxt: '',
        a: 1
      },
      overLuminance: false,
      overLuminanceComplementary: false
    }
  },
  created: function () {
    this.converter()
  },
  watch: {
    colors: {
      handler: function () {
        this.converter()
      },
      deep: true
    }
  },
  mounted: function () {
    this.path = location.pathname
    this.converter()
    document.dispatchEvent(new Event('x-app-rendered'))
  },
  methods: {
    calcSrgb: function (rgbTarget) {
      const srgbTarget = (rgbTarget / 255) <= 0.03928 ? (rgbTarget / 255) / 12.92 : (((rgbTarget / 255) + 0.055) / 1.055) ** 2.4
      return srgbTarget
    },
    judgeContraRatioAsBackground: function (bool) {
      const ratioData = this.calcContrastRatioAsBackground(this.hsl2rgb(this.colors.hsl.h, this.colors.hsl.s, this.colors.hsl.l))
      const LargeRatioResult = ratioData < 3.0 ? 'NG' : ratioData < 4.5 ? 'AA' : 'AAA'
      const RatioResult = ratioData < 4.5 ? 'NG' : ratioData < 7.0 ? 'AA' : 'AAA'
      return bool ? LargeRatioResult : RatioResult
    },
    judgeContraRatioAsFront: function (bool) {
      const ratioData = this.calcContrastRatio(this.hsl2rgb(this.colors.hsl.h, this.colors.hsl.s, this.colors.hsl.l))
      const LargeRatioResult = ratioData < 3.0 ? 'NG' : ratioData < 4.5 ? 'AA' : 'AAA'
      const RatioResult = ratioData < 4.5 ? 'NG' : ratioData < 7.0 ? 'AA' : 'AAA'
      return bool ? LargeRatioResult : RatioResult
    },
    calcLuminance: function (rgb) {
      const _r = this.calcSrgb(rgb.r)
      const _g = this.calcSrgb(rgb.g)
      const _b = this.calcSrgb(rgb.b)
      const L = 0.2126 * _r + 0.7152 * _g + 0.0722 * _b
      return L
    },
    calcContrastRatio: function (rgb) {
      const backgroundLuminance = this.calcLuminance(rgb)
      const lightBackgroundRatio = (backgroundLuminance + 0.05) / (0 + 0.05)
      const darkBackgroundRatio = (1 + 0.05) / (backgroundLuminance + 0.05)
      if (this.black) {
        return lightBackgroundRatio
      } else {
        return darkBackgroundRatio
      }
    },
    calcContrastRatioAsBackground: function (rgb) {
      const backgroundLuminance = this.calcLuminance(rgb)

      const lightBackgroundRatio = (backgroundLuminance + 0.05) / (0 + 0.05)
      const darkBackgroundRatio = (1 + 0.05) / (backgroundLuminance + 0.05)

      return lightBackgroundRatio > darkBackgroundRatio ? lightBackgroundRatio : darkBackgroundRatio
    },
    calcFontColorByContrastRatio: function (rgb) {
      const backgroundLuminance = this.calcLuminance(rgb)

      const lightBackgroundRatio = (backgroundLuminance + 0.05) / (0 + 0.05)
      const darkBackgroundRatio = (1 + 0.05) / (backgroundLuminance + 0.05)

      return lightBackgroundRatio > darkBackgroundRatio ? '#000' : '#FFF'
    },
    pickColor: function (hex) {
      // this.colors.hex = null
      // this.colors.hex = hex
      // this.colors.rgbatxt = this.hex2rgb(hex)
      let _hex = hex.replace(/ |#/g, '')
      if (hex.length === 3) hex = hex.replace(/(.)/g, '$1$1')
      _hex = _hex.match(/../g)
      this.colors = Object.assign({}, this.colors, {
        hex: hex,
        rgbatext: this.hex2rgb(hex),
        rgba: {
          r: parseInt(_hex[0], 16),
          g: parseInt(_hex[1], 16),
          b: parseInt(_hex[2], 16),
          a: this.colors.a
        }
      })

      this.rgb2hsl()
    },
    ajustAngle: function (data) {
      if (data < 0) {
        return (data + 360)
      }

      if (data > 360) {
        return (data - 360)
      }
      return data
    },
    converter: function () {
      var _r = this.colors.rgba.r
      var _g = this.colors.rgba.g
      var _b = this.colors.rgba.b
      var _a = this.colors.rgba.a
      this.colors.rgbatxt = 'rgba(' + _r + ', ' + _g + ', ' + _b + ', ' + _a + ')'

      var max = Math.max(_r, Math.max(_g, _b))
      var min = Math.min(_r, Math.min(_g, _b))
      var sum = max + min

      var R = sum - _r
      var G = sum - _g
      var B = sum - _b

      this.complementary_colors.rgba = {
        r: R,
        g: G,
        b: B,
        a: 1
      }

      this.rgb2hsl()

      this.complementary_colors.hex = this.rgb2hex(R, G, B)
      this.complementary_colors.rgbatxt = this.hex2rgb(this.complementary_colors.hex)

      this.inverse_colors.hex = this.inverse(this.colors.hex)
      this.inverse_colors.rgbatxt = this.hex2rgb(this.inverse_colors.hex)
      this.inverse_colors.rgba = this.hex2rgbData(this.inverse_colors.hex)
    },
    rgb2hex: function (r, g, b) {
      var rgb = b | (g << 8) | (r << 16)
      return '#' + (0x1000000 + rgb).toString(16).slice(1)
    },
    hex2rgb: function (hex) {
      hex = hex.replace(/ |#/g, '')
      if (hex.length === 3) hex = hex.replace(/(.)/g, '$1$1')

      hex = hex.match(/../g)
      return 'rgba(' + parseInt(hex[0], 16) + ', ' + parseInt(hex[1], 16) + ', ' + parseInt(hex[2], 16) + ', ' + this.colors.rgba.a + ')'
    },
    hex2rgbData: function (hex) {
      hex = hex.replace(/ |#/g, '')
      if (hex.length === 3) hex = hex.replace(/(.)/g, '$1$1')

      hex = hex.match(/../g)
      const _r = parseInt(hex[0], 16)
      const _g = parseInt(hex[1], 16)
      const _b = parseInt(hex[2], 16)
      const data = {
        r: _r,
        g: _g,
        b: _b
      }
      return data
    },
    inverse: function (hex) {
      hex = hex.replace('#', '')
      if (hex.length !== 6) { return '#000000' }
      var newColor = ''
      for (var x = 0; x < 3; x++) {
        var colorWK = 255 - parseInt(hex.substr((x * 2), 2), 16)
        if (colorWK < 0) {
          colorWK = 0
        } else {
          colorWK = colorWK.toString(16)
        }
        if (colorWK.length < 2) {
          colorWK = '0' + colorWK
        }
        newColor += colorWK
      }
      return ('#' + newColor)
    },
    rgb2hsl: function () {
      var r = this.colors.rgba.r
      var g = this.colors.rgba.g
      var b = this.colors.rgba.b

      var max = Math.max(r, g, b)
      var min = Math.min(r, g, b)
      var hsl = {
        h: 0,
        s: 0,
        l: (max + min) / 2
      }
      if (max !== min) {
        if (max === r) hsl.h = 60 * (g - b) / (max - min)
        if (max === g) hsl.h = 60 * (b - r) / (max - min) + 120
        if (max === b) hsl.h = 60 * (r - g) / (max - min) + 240
        if (hsl.l <= 127) {
          hsl.s = (max - min) / (max + min)
        } else {
          hsl.s = (max - min) / (510 - max - min)
        }
      }
      if (hsl.h < 0) {
        hsl.h = hsl.h + 360
      }
      this.colors.hsl.h = Math.round(hsl.h)
      this.colors.hsl.s = Math.round(hsl.s * 100)
      this.colors.hsl.l = Math.round((hsl.l / 255) * 100)
    },
    hsl2hex: function (h, s, l) {
      var r, g, b, m, c, x

      if (!isFinite(h)) h = 0
      if (!isFinite(s)) s = 0
      if (!isFinite(l)) l = 0
      h /= 60
      if (h < 0) h = 6 - (-h % 6)
      h %= 6
      s = Math.max(0, Math.min(1, s / 100))
      l = Math.max(0, Math.min(1, l / 100))
      c = (1 - Math.abs((2 * l) - 1)) * s
      x = c * (1 - Math.abs((h % 2) - 1))
      if (h < 1) {
        r = c
        g = x
        b = 0
      } else if (h < 2) {
        r = x
        g = c
        b = 0
      } else if (h < 3) {
        r = 0
        g = c
        b = x
      } else if (h < 4) {
        r = 0
        g = x
        b = c
      } else if (h < 5) {
        r = x
        g = 0
        b = c
      } else {
        r = c
        g = 0
        b = x
      }
      m = l - c / 2
      r = Math.round((r + m) * 255)
      g = Math.round((g + m) * 255)
      b = Math.round((b + m) * 255)
      return this.rgb2hex(r, g, b)
    },
    hsl2rgb: function (h, s, l) {
      var r, g, b, m, c, x

      if (!isFinite(h)) h = 0
      if (!isFinite(s)) s = 0
      if (!isFinite(l)) l = 0
      h /= 60
      if (h < 0) h = 6 - (-h % 6)
      h %= 6
      s = Math.max(0, Math.min(1, s / 100))
      l = Math.max(0, Math.min(1, l / 100))
      c = (1 - Math.abs((2 * l) - 1)) * s
      x = c * (1 - Math.abs((h % 2) - 1))
      if (h < 1) {
        r = c
        g = x
        b = 0
      } else if (h < 2) {
        r = x
        g = c
        b = 0
      } else if (h < 3) {
        r = 0
        g = c
        b = x
      } else if (h < 4) {
        r = 0
        g = x
        b = c
      } else if (h < 5) {
        r = x
        g = 0
        b = c
      } else {
        r = c
        g = 0
        b = x
      }
      m = l - c / 2
      r = Math.round((r + m) * 255)
      g = Math.round((g + m) * 255)
      b = Math.round((b + m) * 255)
      var rgb = {
        r: r,
        g: g,
        b: b
      }
      return rgb
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ColorSearch {
  width: 100%;
  max-width: 1440px;left:0;right:0;margin-left:auto;margin-right:auto;
  height: 0 auto;
  padding-left:20px;
  padding-right:20px;
  text-align: left;
  font-size: 9px;
  padding-bottom:200px;
}

.darken {
  background: #000;
}

.title-msg {
  text-align : left;
  margin-bottom: 20px;
  padding-top: 20px;
  font-size:.8rem;
}

.todark {
  height: 17px;
  vertical-align: middle;
}

.color-container {
  height: 100%;
  width: 100%;
  top:0;
  left:0;
  display:flex;
  flex-wrap: wrap;
  border-radius: 7px;
  box-shadow: 0px 15px 55px rgba(0,0,0,.1);
  padding:20px;
  padding-bottom:20px;
  transition-property: color background-color;
  transition-duration: .4s;
  transition-timing-function:ease-in-out;

}

.cssgen {
  text-align: left;
  border: 1px solid #eee;
  border-radius: 3px;
  height: 355px;
  width: 350px;
  margin: 1%;
  padding: 10px;
  background: #eee;
}

.cssgen > pre {
  font-size: 14px;
  line-height: 20px;
  background: none;
  border: none;
}

.ajast {
  margin: 1%;
}

.light {
  color: #fff;
}

.dark {
  color: #000;
}

.section-title {
  width: 100%;
  font-size: 15px;
  line-height: 25px;
  color: #000;
  background: none;
  box-shadow: 0px 1px 5px rgba(0,0,0,0.5);
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  margin-top:10px;
  margin-bottom:10px;
  transition-property: color;transition-duration: .6s;transition-delay: 0;
}

.blackTitle {
  box-shadow: 0px 0px 10px rgba(255,255,255,1) inset;
  color: #fff;
}

.color-font {
  width: 100%;
  font-size: 15px;
  text-align: left;
}

.result-bg {
  height: 63px;
  width: 63px;
  background: #aaa;
  border-radius: 50%;
  margin: 3px;
  padding-top:18px;
  font-size: 7px;
  cursor:pointer;
  text-align: center;
  user-select: none;
}

.result-nbg {
  height: 63px;
  width: 63px;
  background: #aaa;
  border-radius: 5px;
  margin: 3px;
  padding-top:16px;
  font-size: 8px;
  text-align: center;
}

.result-cover {
  height: 300px;
  width: 100%;
  background: none;
  border-radius: 5px;
  margin: 3px;
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  font-size: 28px;
  text-align: left;
}

ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.vc-sketch {
  width:270px;
}

.vc-sketch-field .vc-input__input {
  width:90%;
}

.noSelectLine {
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}

.F13{
  display:block;
  font-size: 13px;
  margin-bottom:3px;
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
.degF13{
  display:block;
  font-size: 13px;
  margin-left:10px;
  margin-bottom:3px;
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}

.F14px{
  font-size: 14px;
}

.Fb14pt{
  font-size: 18.66667px;
  font-weight: bold;
}

.F18pt{
  font-size: 24px;
}

.Fb18pt{
  font-size: 24px;
  font-weight: bold;
}

.F22pt{
  font-size: 29.333333px;
}

.F30{
  display:block;
  font-size: 30px;
}

.F40{
  display:block;
  font-size: 40px;
}

.hider {
display: none;
}

.marginAjuster {
margin-bottom: 15px;
}

@media screen and (max-width: 570px) {
  .result-cover {
    padding-top:15px;
    padding-left:12px;
    padding-right:12px;
    font-size: 14px;
  }
  .cssgen {
    display: none;
  }
}
</style>
