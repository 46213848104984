<template>
  <div id="app">
   <main id="main">
      <div id="main-in">
        <div id="main-visual"></div>
        <router-view/>
      </div>
    </main>
    <div class="footer">
    </div>
    <div id="overlay"></div>
    <vue-cookie-accept-decline
      :ref="'myPanel1'"
      :elementId="'myPanel1'"
      :debug="false"
      :position="'bottom-left'"
      :type="'floating'"
      :disableDecline="false"
      :transitionName="'slideFromBottom'"
      :showPostponeButton="false"
      @status="cookieStatus"
      @clicked-accept="cookieClickedAccept"
      @clicked-decline="cookieClickedDecline">

      <!-- Optional -->
      <div slot="postponeContent">
          &times;
      </div>

      <!-- Optional -->
      <div slot="message">
        ABIERRE.COM(以下、当サイト)では、サイトの統計分析と改善のためにGoogle社のGoogleアナリティクスを使用しています。
        また、当サイトの高速化、不正アクセスからの保護のためのファイアウォールを目的としてCloudflareを使用しています。
        これらはcookieを用いることで品質維持をおこなっております。
        <a href="https://abierre.com/notice" target="_blank">詳しくはこちら</a>
      </div>

      <!-- Optional -->
      <div slot="declineContent">
         オプトアウト継続
      </div>

      <!-- Optional -->
      <div slot="acceptContent">
          承諾
      </div>
    </vue-cookie-accept-decline>
  </div>
</template>
<script>
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'
export default {
  name: 'App',
  components: {
  },
  data () {
    return {
      status: null
    }
  },
  beforeCreate () {
    this.$ga.disable()
  },
  created () {
  },
  mounted () {
  },
  computed: {
    statusText () {
      return this.status || 'No cookies'
    }
  },
  watch: {
  },
  methods: {
    cookieStatus (status) {
      this.status = status
      if (this.status === 'accept') {
        this.$ga.enable()
      } else {
        this.$ga.disable()
      }
    },
    cookieClickedAccept () {
      this.status = 'accept'
      this.$ga.enable()
    },
    cookieClickedDecline () {
      this.status = 'decline'
      this.$ga.disable()
    },
    cookieRemovedCookie () {
      this.status = null
      this.$refs.myPanel1.init()
    },

    removeCookie () {
      this.$refs.myPanel1.removeCookie()
    }
  }
}
</script>
<style src="./assets/App.css"></style>
