<template>
  <div class='ContrastAnalyzer'>
    <div class='title-msg'>
      <p>W3C WCAG 2.1に則った、色のコントラスト比を調べるツールです。</p>
      <p><a href='https://www.w3.org/TR/WCAG21/#contrast-minimum'>https://www.w3.org/TR/WCAG21/#contrast-minimum</a></p>
      <p>( ※ 詳しくは、「<a href='/article/5c18da1189599b92b890ecc1'>フォントの色はW3CのWCAGを用いて判断する</a>」の記事を参照ください！ )</p>
    </div>
    <div id='dpi'></div>
    <div class='contrast-container'>
      <div class='pck ContrastRatioBox'>
        <div class='label'>コントラスト比</div>
        <div class='ratio-display'>{{ ratio.toFixed(1) }}:1</div>
      </div>

      <div
        class='pck'
      >
        <div class='label'>
          Background Color ( 背景色 )
        </div>
        <div
          id='picker1'
          acp-color='#053257'
          acp-show-rgb='yes'
          acp-show-hsl='no'
          acp-show-hex='yes'
          acp-show-alpha='no'
        >
        </div>
      </div>

      <div
        class='pck'
      >
        <div class='label'>
          Foreground Color ( テキスト色 )
        </div>
        <div
          id='picker2'
          acp-color='#cccccc'
          acp-show-rgb='yes'
          acp-show-hsl='no'
          acp-show-hex='yes'
          acp-show-alpha='no'
        >
        </div>
      </div>
      <div class='pck'>
        <div class='label'>PREVIEW : 標準テキスト ( 14px )</div>
        <div
          class='result-ratio-small'
          :style='[ForegroundColor, BackgroundColor]'
        >
          Normal Text Size, <span class='jpn'>標準テキストサイズ</span>
        </div>
        <div class='label'>PREVIEW : 大きいテキスト ( 通常 )</div>
        <div
          class='result-ratio-large'
          :style='[ForegroundColor, BackgroundColor]'
        >
          18pt, <span class='jpn-large'>日本語:22pt</span>
        </div>
        <div class='label'>PREVIEW : 大きいテキスト ( 太字 )</div>
        <div
          class='result-ratio-large-bold'
          :style='[ForegroundColor, BackgroundColor]'
        >
          14pt, <span class='jpn-bold'>日本語:18pt</span>
        </div>
      </div>

      <div class='pck' >
        <div class='label'>
          標準テキストの判定
        </div>
        <div
          class='result-msg'
          :style='GeneralRatioResult'
        >
          {{ ratio &lt; 4.5 ? 'NG (Ratio &lt; 4.5)' : ratio &lt; 7.0 ? 'Level AA (4.5 &lt; Ratio &gt; 7.0)' : 'Level AAA (Ratio &gt; 7.0)' }}
        </div>
        <div class='label'>
          大きいテキスト ( 通常 ) の判定
        </div>
        <div
          class='result-msg'
          :style='LargeRatioResult'
        >
          {{ ratio &lt; 3.0 ? 'NG (Ratio &lt; 3.0)' : ratio &lt; 4.5 ? 'Level AA (3.0 &lt; Ratio &gt; 4.5)' : 'Level AAA (Ratio &gt; 4.5)' }}
        </div>
        <div class='label'>
          大きいテキスト ( 太字 ) の判定
        </div>
        <div
          class='result-msg'
          :style='LargeRatioResult'
        >
          {{ ratio &lt; 3.0 ? 'NG (Ratio &lt; 3.0)' : ratio &lt; 4.5 ? 'Level AA (3.0 &lt; Ratio &gt; 4.5)' : 'Level AAA (Ratio &gt; 4.5)' }}
        </div>

      </div>

      <div
        class='pck calc'
      >
        <p class='label'>計算内容 ( Javascript )</p>
        <pre>
[ BackgroundLuminance = {{ BackgroundLuminance }} ]
- BackgroundColorRGB: { r: {{ BackgroundData.r }}, g: {{ BackgroundData.g }}, b: {{ BackgroundData.b }} }
- R : {{ calcSrgb(BackgroundData.r) }} = ({{ BackgroundData.r }} / 255) &lt;= 0.03928 ? ({{ BackgroundData.r }} / 255) / 12.92 : ((({{ BackgroundData.r }} / 255) + 0.055) / 1.055) ** 2.4
- G : {{ calcSrgb(BackgroundData.g) }} = ({{ BackgroundData.g }} / 255) &lt;= 0.03928 ? ({{ BackgroundData.g }} / 255) / 12.92 : ((({{ BackgroundData.g }} / 255) + 0.055) / 1.055) ** 2.4
- B : {{ calcSrgb(BackgroundData.b) }} = ({{ BackgroundData.b }} / 255) &lt;= 0.03928 ? ({{ BackgroundData.b }}/ 255) / 12.92 : ((({{ BackgroundData.b }} / 255) + 0.055) / 1.055) ** 2.4
- L ( BackgroundLuminance ) = 0.2126 * R + 0.7152 * G + 0.0722 * B
 =&gt; {{ BackgroundLuminance }} = 0.2126 * {{ calcSrgb(BackgroundData.r) }} + 0.7152 * {{ calcSrgb(BackgroundData.g)}} + 0.0722 * {{ calcSrgb(BackgroundData.b)}}

[ ForegroundLuminance = {{ ForegroundLuminance }} ]
- ForegroundColorRGB: { r: {{ ForegroundData.r }}, g: {{ ForegroundData.g }}, b: {{ ForegroundData.b }} }
- R : {{ calcSrgb(ForegroundData.r) }} = ({{ ForegroundData.r }} / 255) &lt;= 0.03928 ? ({{ ForegroundData.r }} / 255) / 12.92 : ((({{ ForegroundData.r }} / 255) + 0.055) / 1.055) ** 2.4
- G : {{ calcSrgb(ForegroundData.g) }} = ({{ ForegroundData.g }} / 255) &lt;= 0.03928 ? ({{ ForegroundData.g }} / 255) / 12.92 : ((({{ ForegroundData.g }} / 255) + 0.055) / 1.055) ** 2.4
- B : {{ calcSrgb(ForegroundData.b) }} = ({{ ForegroundData.b }} / 255) &lt;= 0.03928 ? ({{ ForegroundData.b }}/ 255) / 12.92 : ((({{ ForegroundData.b }} / 255) + 0.055) / 1.055) ** 2.4
- L ( ForegroundLuminance ) = 0.2126 * R + 0.7152 * G + 0.0722 * B
 =&gt; {{ ForegroundLuminance }} = 0.2126 * {{ calcSrgb(ForegroundData.r) }} + 0.7152 * {{ calcSrgb(ForegroundData.g)}} + 0.0722 * {{ calcSrgb(ForegroundData.b)}}

[ ContrastRatio = {{ ratio }} : 1 ]
- L1 : {{ ForegroundLuminance &lt; BackgroundLuminance ? ForegroundLuminance : BackgroundLuminance }} = ForegroundLuminance &lt; BackgroundLuminance ? ForegroundLuminance : BackgroundLuminance
- L2 : {{ ForegroundLuminance &lt; BackgroundLuminance ? BackgroundLuminance : ForegroundLuminance }} = ForegroundLuminance &lt; BackgroundLuminance ? BackgroundLuminance : ForegroundLuminance
- ContrastRatio = (L1 + 0.05) / (L2 + 0.05)
 =&gt; {{ ratio }} = ({{ isLight(ForegroundLuminance, BackgroundLuminance) }} + 0.05) / ({{ isDark(ForegroundLuminance, BackgroundLuminance) }} + 0.05)

        </pre>
      <p class='label'>備考</p>
        <pre>
- Luminance Range ( 輝度の範囲 )
0.0 ~ 1.0 ( #000 ~ #fff )

- ContrastRatio Range ( コントラス比の範囲 )
1.0 ~ 21.0 : 1

- BackgroundLuminance
背景の輝度、背景色から算出した輝度

- ForegroundLuminance
文字の輝度、文字色から算出した輝度

- ContrastRatio
背景の輝度と、文字の輝度から算出されるコントラスト比

- ポイント(pt)とピクセル(px)
1px = 0.75pt
1pt = 1.333..px
1pt = 0.352778mm
1インチ = 72pt = 25.4mm
        </pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContrastAnalyzer',
  props: {
  },
  components: {
  },
  metaInfo () {
    return {
      title: 'Contrast Analyzer',
      titleTemplate: null,
      link: [
        {
          vmid: 'canonical',
          rel: 'canonical',
          href: 'https://abierre.com/tools/contrast'
        },
        {
          vmid: 'alternate',
          rel: 'alternate',
          hreflang: 'ja',
          href: 'https://abierre.com/tools/contrast'
        }
      ],
      script: [
        {
          vmid: 'jsonld',
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Article',
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': 'https://abierre.com/tools/contrast'
            },
            headline: 'Color Search',
            articleBody: 'W3C WCAG 2.1に則った、コントラスト比を調べるツールです。',
            articleSection: 'Tools',
            image: [
              'https://img.abierre.com/OGP8.png'
            ],
            datePublished: '2018-12-13T01:19:09+09:00',
            dateModified: '2018-12-13T01:19:09+09:00',
            author: {
              '@type': 'Person',
              name: 'ISSIE'
            },
            publisher: {
              '@type': 'Organization',
              name: 'ABIERRE',
              logo: {
                '@type': 'ImageObject',
                url: 'https://img.abierre.com/JsonLdImg.png'
              }
            },
            description: 'W3C WCAG 2.1に則った、コントラスト比を調べるツールです。'
          }),
          type: 'application/ld+json'
        }
      ],
      meta: [
        { charset: 'utf-8' },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'Contrast Analyzer'
        },
        {
          vmid: 'description',
          name: 'description',
          content: 'W3C WCAG 2.1に則った、コントラスト比を調べるツールです。'
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: 'W3C WCAG 2.1に則った、コントラスト比を調べるツールです。'
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: 'https://abierre.com/tools/contrast'
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'product'
        },
        {
          vmid: 'og:image',
          name: 'og:image',
          content: 'https://img.abierre.com/OGP63.png'
        },
        {
          vmid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          vmid: 'twitter:image',
          name: 'twitter:image',
          content: 'https://img.abierre.com/OGP63.png'
        },
        {
          vmid: 'twitter:text:title',
          name: 'twitter:text:title',
          content: 'Contrast Analyzer'
        },
        {
          vmid: 'twitter:title',
          name: 'twitter:title',
          content: 'Contrast Analyzer'
        },
        {
          vmid: 'twitter:description',
          name: 'twitter:description',
          content: 'W3C WCAG 2.1に則った、コントラスト比を調べるツールです。'
        }
      ]
    }
  },
  data () {
    return {
      ForegroundData: {
        r: 236,
        g: 238,
        b: 231
      },
      BackgroundData: {
        r: 5,
        g: 50,
        b: 87
      },
      ForegroundColor: {
        color: 'rgb(204, 204, 204)'
      },
      BackgroundColor: {
        background: 'rgb(5, 50, 87)'
      },
      ForegroundLuminance: 0,
      BackgroundLuminance: 0,
      ratio: 0,
      GeneralRatioResult: {
        color: '#126707'
      },
      LargeRatioResult: {
        color: '#126707'
      }
    }
  },
  watch: {
    BackgroundLuminance: function () {
      this.calcContrastRatio()
    },
    ForegroundLuminance: function () {
      this.calcContrastRatio()
    }
  },
  mounted: function () {
    this.init()
    this.ForegroundLuminance = this.calcLuminance(204, 204, 204)
    this.BackgroundLuminance = this.calcLuminance(5, 50, 87)
    this.calcContrastRatio()
    this.checkRatioStatus()
    document.dispatchEvent(new Event('x-app-rendered'))
  },
  methods: {
    init: function () {
      const AColorPicker = require('a-color-picker')
      const that = this
      AColorPicker.from('#picker1').on('change', function () {
        const rgb = this.rgb
        that.BackgroundColor = {
          background: 'rgb(' + rgb[0] + ', ' + rgb[1] + ', ' + rgb[2] + ')'
        }
        that.BackgroundData = {
          r: rgb[0],
          g: rgb[1],
          b: rgb[2]
        }
        that.BackgroundLuminance = that.calcLuminance(rgb[0], rgb[1], rgb[2])
      })
      AColorPicker.from('#picker2').on('change', function () {
        const rgb = this.rgb
        that.ForegroundColor = {
          color: 'rgb(' + rgb[0] + ', ' + rgb[1] + ', ' + rgb[2] + ')'
        }
        that.ForegroundData = {
          r: rgb[0],
          g: rgb[1],
          b: rgb[2]
        }

        that.ForegroundLuminance = that.calcLuminance(rgb[0], rgb[1], rgb[2])
      })
    },
    isLight (a, b) {
      return a > b ? a : b
    },
    isDark (a, b) {
      return a < b ? a : b
    },
    calcLuminance: function (r, g, b) {
      const _r = this.calcSrgb(r)
      const _g = this.calcSrgb(g)
      const _b = this.calcSrgb(b)
      const L = 0.2126 * _r + 0.7152 * _g + 0.0722 * _b
      return L
    },
    calcContrastRatio: function () {
      let L1 = null
      let L2 = null
      if (this.ForegroundLuminance < this.BackgroundLuminance) {
        L1 = this.BackgroundLuminance
        L2 = this.ForegroundLuminance
      } else {
        L1 = this.ForegroundLuminance
        L2 = this.BackgroundLuminance
      }
      const _ratio = ((L1 + 0.05) / (L2 + 0.05))
      this.ratio = _ratio
      this.checkRatioStatus(_ratio)
    },
    calcSrgb: function (rgbTarget) {
      const srgbTarget = (rgbTarget / 255) <= 0.03928 ? (rgbTarget / 255) / 12.92 : (((rgbTarget / 255) + 0.055) / 1.055) ** 2.4
      return srgbTarget
    },
    checkRatioStatus: function (ratioData) {
      this.LargeRatioResult = {
        color: ratioData < 3.0 ? '#F44' : ratioData < 4.5 ? '#000' : '#126707',
        background: ratioData < 3.0 ? '#000' : ratioData < 4.5 ? '#ccc' : '#FFF'
      }
      this.GeneralRatioResult = {
        color: ratioData < 4.5 ? '#F44' : ratioData < 7.0 ? '#000' : '#126707',
        background: ratioData < 4.5 ? '#000' : ratioData < 7.0 ? '#ccc' : '#FFF'
      }
    }
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
.ContrastAnalyzer {
  width: 100%;
  max-width: 1440px;left:0;right:0;margin-left:auto;margin-right:auto;
  height: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom:200px;
  text-align: left;
}

.title-msg {
  text-align : left;
  margin-bottom: 20px;
  padding-top: 20px;
  font-size:.8rem;
}

.contrast-container {
  display: flex;
  flex-wrap:wrap;
  height: 100%;
  width: 100%;
  top:0;
  left:0;
  display:flex;
  flex-wrap: wrap;
  padding-bottom:20px;
  margin-left: -10px;
}

.pck {
  margin:5px 1px 5px 10px;
  width: 0 auto;
  max-width: 100%;
  height: 0 auto;
}

.pck-max {
  border-radius: 3px;
  box-shadow: 0px 1px 3px rgba(0,0,0,.3);
  margin:0px 10px 0px 10px;
  padding:5px 5px 5px 5px;
  width: 100%;
}

.label {
  font-size: 13px;
  border-radius: 3px;
  margin-bottom: 3px;
  padding : 5px 5px 5px 5px;
  box-shadow: 0px 1px 3px rgba(0,0,0,.3);
}

.ratio-label {
  font-size: 20px;
  line-height: 40px;
  border-radius: 3px;
  border: 2px solid #000;
  margin-bottom: 25px;
  padding : 10px 10px 10px 10px;
}

.ContrastRatioBox {
width: 100%;
}

.jpn {
  font-size:14px;
}

.jpn-large {
  font-size:29.33px;
}

.jpn-bold {
  font-size:24px;
}

.a-color-picker {
max-width: 170px;
}

.ratio-display {
  color: #D33;
  font-size: 52px;
  line-height:70px;
}

.result-ratio {
  display: flex;
  width: 100%;
  height: 0 auto;
}

.result-ratio-small {
  width: 100%;
  height:84px;line-height: 59px;
  font-size:14px;
  text-align: center;
  border-radius: 3px;
  padding-top: 12px;
  padding-left:10px;padding-right:10px;
  margin-bottom:5px;
  box-shadow: 0px 1px 5px rgba(0,0,0,.3);
}

.result-ratio-large {
  width: 100%;
  height:84px;line-height: 59px;
  font-size:24px;
  text-align: center;
  border-radius: 3px;
  padding-top: 12px;
  padding-left:10px;padding-right:10px;
  margin-bottom:5px;
  box-shadow: 0px 1px 5px rgba(0,0,0,.3);
}

.result-ratio-large-bold {
  width: 100%;
  height:84px;line-height: 59px;
  font-size:18.67px;
  font-weight:bold;
  text-align: center;
  border-radius: 3px;
  padding-left:10px;padding-right:10px;
  margin-bottom:5px;
  padding-top: 12px;
  box-shadow: 0px 1px 5px rgba(0,0,0,.3);
}

.content-title {
  font-size:25px;
  line-height: 35px;
  color: #431;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.result-box {
  border-radius: 3px;
  padding : 10px 10px 10px 10px;
  box-shadow: 0px 1px 5px rgba(0,0,0,.3);
  height: 0 auto;
}

.result-ratio {
  font-size:27px;
  line-height: 45px;
  color: #d93;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 10px;
}

.result-msg {
  font-size:12px;
  font-weight: bold;
  margin-bottom: 5px;
  height:84px;line-height: 59px;
  border-radius: 3px;
  box-shadow: 0px 1px 5px rgba(0,0,0,.3);
  padding-top: 12px;
  padding-left:7px;padding-right:7px;
  background:#eee;
  text-align:center;
  width: 200px;
}

.calc {
  width:100%;
}

#dpi {
  width: 1in;
  height: 0;
}

</style>
