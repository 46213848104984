import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'

import Analytics from 'vue-analytics'
import CookieAcceptDecline from 'vue-cookie-accept-decline'
import Meta from 'vue-meta'
import ScrollTo from 'vue-scrollto'

Vue.config.silent = true // prod

Vue.config.performance = false // prod
// Vue.config.performance = true // dev

Vue.config.devtools = false // prod
// Vue.config.devtools = true // dev
//
Vue.config.productionTip = false // prod
// Vue.config.productionTip = true // dev
Object.freeze(Vue.config)

Vue.use(Analytics, {
  id: 'UA-41513120-2',
  router,
  debug: {
    enabled: false
  }
})

Vue.component('vue-cookie-accept-decline', CookieAcceptDecline)

Vue.use(Meta, {
  refreshOnceOnNavigation: true
})

Vue.use(ScrollTo)

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered'))
}).$mount('#app')

/* eslint-disable */

delete eval.constractor
console.log(eval)

console.log = () => { return false }
console.warn = () => { return false }
console.error = () => { return false }
console.exception = () => { return false }
console.debug = () => { return false }
console.info = () => { return false }
console.dir = () => { return false }
console.dirxml = () => { return false }
console.group = () => { return false }
console.groupCollapsed = () => { return false }
console.groupEnd = () => { return false }
console.profile = () => { return false }
console.profileEnd = () => { return false }
console.table = () => { return false }
console.time = () => { return false }
console.timeEnd = () => { return false }
console.timeStamp = () => { return false }
console.trace = () => { return false }
delete console.prototype
Object.freeze(console)

Function = () => { return false }
Object.freeze(Function)


({}).__proto__ = null
Object.freeze(({}))
/* eslint-enable */
