<template>
  <div class="InputText">
    <label
      class="input-label"
      :class="{
        focused: isFocus,
        notfocus: !isFocus,
        isEdit: isEdit
      }"
      @click="setFocus"
    >
      {{ label }}
      <span
        class="dispLength"
        :class="isReachedMaxLength ? 'alertC' : 'defaultC'"
        v-if="maxLength !== undefined && inputData.length > 0"
      >
        {{ inputData.length }} / {{ maxLength }}
      </span>
    </label>
    <div class="inputContainer">
    <input
      ref="input"
      class="input-box"
      :class="isReachedMaxLength ? 'alert' : 'default'"
      :style="'width:' + width"
      v-model="inputData"
      @focus="onFocus"
      @blur="onBlur"
    />
    <div
      class="unit"
      v-show="unit"
    >
      {{ unit }}
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputText',
  props: [
    'value',
    'label',
    'maxLength',
    'width',
    'unit'
  ],
  data () {
    return {
      isFocus: false,
      isEdit: false,
      isReachedMaxLength: false
    }
  },
  computed: {
    inputData: {
      get () {
        return this.value + ''
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    inputData () {
      this.isEdit = false
      if (this.inputData === '') {
        this.isFocus = false
      } else {
        this.isFocus = true
      }

      if (this.maxLength === undefined) return
      if (this.inputData === '') return
      if (this.inputData.length > this.maxLength) {
        this.isReachedMaxLength = true
      } else {
        this.isReachedMaxLength = false
      }
    }
  },
  mounted () {
    if (this.maxLength === undefined) return
    if (this.inputData === '') return
    if (this.inputData.length > this.maxLength) {
      this.isReachedMaxLength = true
    } else {
      this.isReachedMaxLength = false
    }

    this.isEdit = false
    if (this.inputData === '') {
      this.isFocus = false
    } else {
      this.isFocus = true
    }
  },
  methods: {
    setFocus () {
      this.$refs.input.focus()
    },
    onFocus () {
      this.isFocus = true
      this.isEdit = true
    },
    onBlur () {
      this.isEdit = false
      if (this.inputData === '') {
        this.isFocus = false
      } else {
        this.isFocus = true
      }
    }
  }
}
</script>
<style scoped>
.InputText {
  position: relative;
  width: 100%;
  height: 40px;
  margin-top:40px;
  display: flex;
}

.input-label {
  position: absolute;
  display: block;
  text-align: left;
  line-height: 20px;
  font-size: 13px;
  padding-top: 6px;
  width:0 auto;min-width: 250px;
  top: -30px;
  left: 0px;
  z-index: 1;
  cursor: text;
}

.focused {
  color: #620;
  font-size: 15px;
  letter-spacing: 2px;
  transform: translate(0px,0px);
  transition-property: transform font-size;transition-duration: 0.2s;transition-delay: 0;
  text-shadow: 0 1.36px 1.896px #c4b59daa,0 -2px 1px #fff;
}
.notfocus {
  color: #888;
  transform: translate(10px,35px);
  transition-property: transform font-size;transition-duration: 0.2s;transition-delay: 0;
}

.isEdit {
  filter: drop-shadow( 0px 0px 2px rgba(234,245,30,.09));
}

.inputContainer {
  position: absolute;
  width: 100%;
  height: 30px;
  display: flex;
}
input {
  caret-color: #770;
}
.input-box {
  font-family: "TsukuARdGothic-Regular", "M PLUS 1p", "Yu Gothic", sans-serif;
  background: none;
  display: block;
  width: 100%;
  height: 40px;
  padding: 10px;
  outline: 0;
  color: #000;
  -webkit-appearance:none;
  font-size: 16.2px;
  font-weight: 100;
  line-height:20px;
  letter-spacing: 1px;
  border-top: hidden;
  border-left: hidden;
  border-right: hidden;
  border: none;
  border-radius: 5px;
  caret-color: #aaa;
  box-shadow: -2px -2px 2px #fafafa inset, 1px 1px 1px 1px #ccaa4422 inset;
  background: #fffefa;
}

.input-box:focus {
  background: #fff;
  box-shadow: 0px 0px 4px #77220044 inset;
}

.dispLength {
  padding: 3px;
  font-size: 10px;
}

.unit {
  padding-top: 8px;
  padding-left: 5px;
  font-size: 13px;
}

.alert {
  color: #a00;
}

.default {
  color: black;
}

.alertC {
  color: #a00;
}
.defaultC {
  color: black;
}

@media screen and (max-width: 620px) {
  .InputText {
    display: block;
  }
}
</style>
